import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Grid, CircularProgress } from "@mui/material";
import { Screen, HelpSection, AppNavBar } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { isLoading, orgActions, userActions, isUserCreated, getFetchedUser } from "../../store";
import { UserForm } from "../Form";
import "./style.scss";

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId } = useParams();
  const loading = useSelector(isLoading);
  const isCreated = useSelector(isUserCreated);
  const [searchParams] = useSearchParams();
  const user = useSelector(getFetchedUser);
  const getUserId = () => {
    if (userId) {
      return userId
    }
    return searchParams.get("id")
  }
  useEffect(() => {
    const userId = getUserId();
    if (!userId) {
      return navigate("/page-not-found")
    }
    dispatch(userActions.fetchCountryCodes())
    dispatch(userActions.fetchRoles())
    dispatch(orgActions.fetchDepartments())
    dispatch(orgActions.fetchDesignations())
    dispatch(userActions.fetchUser({ userId }))
  }, [])
  useEffect(() => {
    if (isCreated) {
      navigate('/')
    }
  }, [isCreated])
  useEffect(() => {
    if (user === "NotFound") {
      navigate('/page-not-found')
    }
  }, [user])
  const onSubmit = (formData) => {
    const userId = getUserId()
    const body = { ent_usr: formData, user_id: Number(userId) }
    dispatch(userActions.updateUser(body))
  }
  return (
    <div className='col w-100 h-100 o-hide'>
      {/* <AppNavBar user={null} items={[]} className="app-nav" /> */}
      <Screen className='user-add col f-rest o-hide'>
        <Grid container spacing={0} classes={{root: 'o-hide'}}>
          <Grid item xs={12} sm={12} md={9} lg={9} classes={{ root: 'col h-100  o-hide' }} direction="column">
            {
              (user && user !== 'NotFound') ?
                <>
                  <h1 className='exo2 h5 med page-head'>Add User Details</h1>
                  <UserForm
                    nonEditable={['email']}
                    btnSubmit='Submit' btnReset='Back' user={user} showCancel={false}
                    onSubmit={onSubmit} disabled={loading} />
                </>
                :
                <div className='col f-rest v-ctr h-ctr'>
                  <CircularProgress />
                </div>
            }
          </Grid>
          <HelpSection xs={12} sm={12} md={3} lg={3} />
        </Grid>
      </Screen>
    </div>
  )
}
