import React from 'react';
import { useSelector } from "react-redux";
import { Navigate } from 'react-router-dom';
import { getCurrentUser } from "../../store";

export const RoleBasedComponent = (props) => {
  const { notAllowedRoles, allowedRoles, Component, ...rest } = props;
  const user = useSelector(getCurrentUser);
  const hasAccess = () => {
    let isAllowed = (allowedRoles || []).includes(user.role.name);
    if (isAllowed) {
      return isAllowed;
    }
    if (notAllowedRoles === undefined) {
      return false
    }
    isAllowed = (notAllowedRoles || []).includes(user.role.name);
    return !isAllowed;
  }
  const isAllowed = hasAccess();
  return (
    isAllowed ? <Component {...rest} /> : <Navigate replace to="/page-not-found" />
  )
}
