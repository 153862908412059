import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import { Screen, HelpSection, useNavigateBack } from "../../components";
import { orgActions, getCurrentUserOrg, isOrgUpdateSuccess } from "../../store";
import { OrgForm } from "./Components/Form";
import { useSelector, useDispatch } from "react-redux";
import "./style.scss";

export default (props) => {
  const dispatch = useDispatch();
  const navigateBack = useNavigateBack()
  const org = useSelector(getCurrentUserOrg)
  const isUpdated = useSelector(isOrgUpdateSuccess);
  const [state, setState] = useState({});
  useEffect(() => {
    if (!org) {
      dispatch(orgActions.getUserOrg())
    }
  }, [])
  useEffect(() => {
    if (isUpdated) {
      handleCancel();
    }
  }, [isUpdated])
  const onUpdate = (formData) => {
    formData.append('ent_org_id', org.id);
    dispatch(orgActions.creatOrUpdateOrg({org: formData}));
  }
  const handleCancel = () => {
    dispatch(orgActions.createOrUpdateOrgStatus({ create: false, updated: false }));
    navigateBack()
  }
  return (
    <div className='col w-100 h-100 o-hide'>
      <Screen className='org-add col f-rest o-hide'>
        <Grid container spacing={0} classes={{root: 'o-hide h-100'}}>
          <Grid item xs={12} sm={12} md={9} lg={9} classes={{root: 'oy-auto h-100'}}>
            <h1 className='exo2 h5 med page-head'>Edit Org Information</h1>
            {
              Boolean(org) && (
                <OrgForm
                  edit
                  deactivate={false}
                  org={org}
                  btnSubmit='Save Changes'
                  btnReset='Cancel'
                  onSubmit={onUpdate}
                  onReset={handleCancel}/>
              )
            }
          </Grid>
          <HelpSection xs={12} sm={12} md={3} lg={3} />
        </Grid>
      </Screen>
    </div>
  )
}
