import React, { useEffect, useState } from 'react';
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Table, Input, PrimaryButton, SearchIcon, PlusIcon, Button, ArrowDropUpIcon, ConfirmationBox } from "../../components";
import { orgActions, userActions, getAcceptedOrgs, getPendingOrgs, getCurrentUser, isAdmin, isArchitect, isOrganizer, getOrgDetailsById } from "../../store";

import "./style.scss";

const Columns = [
  { label: "Org Name", width: '20%', align: 'left', attr: 'brandname', className: "link", isClickable: true },
  { label: "Organiser", width: '30%', attr: 'organiserName', align: 'left'},
  { label: "Email", width: '25%', attr: 'organiserEmail', align: 'left' },
  { label: "Accepted Date", width: '25%', attr: 'mappingDate'},
];
const MappingRequestColumns = [
  { label: "Org Name", width: '20%', align: 'left', attr: 'brandname' },
  { label: "Requester", width: '25%', attr: 'requester', align: 'left' },
  { label: "Request Date", width: '30%', attr: 'requestDateTime' },
  { label: "Action(s)", width: '25%', type: 'actions', dataCellClassName: "row h-ctr actions capital" }
]

export default (props) => {
  const { orgId } = useParams();
  const org = useSelector(getOrgDetailsById.bind(null, orgId))
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser)
  const canAction = isAdmin(user) || isArchitect(user)
  const [state, setState] = useState({ search: '', hideModal: false, show: false });
  const pendingOrgs = useSelector(getPendingOrgs.bind(null, state.search));
  const acceptedOrg = useSelector(getAcceptedOrgs.bind(null, state.search));

  const getAcceptedColumns = () => {
    const col = Columns.map((_) => ({ ..._ }));
    col[0].className = !orgId && canAction ? 'link' : '';
    col[0].isClickable = !orgId && canAction;
    return col
  }
  useEffect(() => {
    dispatch(orgActions.getAcceptedOrgsSuccess(null));
    dispatch(orgActions.fetchPendingOrgsSuccess(null));
    if (org) {
      dispatch(orgActions.fetchOrgMappings({ orgId: org.id }));
    } else {
      dispatch(orgActions.fetchAcceptedOrgMappings());
      dispatch(orgActions.fetchPendingOrgMappings());
    }
    setState((_) => ({ ..._, show: true }))
  }, [])
  const handleOrgCellClick = (row, col) => {
    if (canAction) {
      navigate("/O/" + row.orgId, {state: {from: "M"}})
    }
  }
  const handleInvite = () => {
    dispatch(orgActions.isOrgUserExistSuccess(null))
    dispatch(userActions.inviteUserSuccess(null))
    if (orgId) {
      navigate("invite")
    } else {
      navigate("M/invite")
    }
  }
  const handleAcceptRejectMapping = (action, row) => {
    const confirmMsg = { title: "External User Mapping", action: action.action, row: row }
    if (action.action === 'accept') {
      confirmMsg.btnOKLabel = 'Accept';
      confirmMsg.btnOKColor = "#00CC55"
      confirmMsg.subtitle = [`Are you sure to accept the organization mapping request of ${row.requester} from ${row.brandname}?`];
    } else if (action.action === 'reject') {
      confirmMsg.btnOKLabel = 'Reject';
      confirmMsg.btnOKColor = "#FF3333"
      confirmMsg.subtitle = [`Are you sure to reject the organization mapping request of ${row.requester} from ${row.brandname}?`];
    }
    setState((_) => ({ ..._, confirmMsg: confirmMsg }))
  }
  const handleOk = () => {
    handleCancel()
    const { org_mapping } = state.confirmMsg.row;
    const body = {
      "user_id": org_mapping.id,
      "vendor_id": org_mapping.vendor_id,
      "customer_id": org_mapping.customer_id,
      "activated": state.confirmMsg.action === 'accept'
    }
    dispatch(orgActions.activateDeactivateOrgMapping(body))
  }
  const handleCancel = () => {
    setState((_) => ({ ..._, confirmMsg: null }));
  }
  return (
    !state.show ? null :
      <div className='col w-100 h-100 org-map-scr'>
        <h3 className='h5 exo2 med title'>External Mapping</h3>
        <div className='row v-ctr h-btn search-row'>
          <Input
            className='h9 search'
            placeholder='Search'
            value={state.search}
            icon={<SearchIcon className='search-icon' />}
            onChange={(e) => setState((_) => ({ ..._, search: e.target.value }))} />
          {
            (canAction || isOrganizer(user)) &&
            <PrimaryButton
              className='h8 btn-invite' text='Invite External Organisation'
              endIcon={<PlusIcon className='h10' />} onClick={handleInvite}
            />
          }
        </div>
        <div className='col f-rest o-hide'>
          <div className='col w-100 h-100 o-hide'>
            <div className='col f-rest oy-auto tbl-container hsc'>
              {
                acceptedOrg ?
                  <Table
                    emptyMessage={state.search ? 'No Match' : 'No Organisations'}
                    columns={getAcceptedColumns()}
                    className='w-100 h-100 tbl-org'
                    rows={acceptedOrg}
                    onCell={handleOrgCellClick}
                  />
                  :
                  <div className='col v-ctr h-ctr w-100 h-100'>
                    <CircularProgress />
                  </div>
              }
            </div>
            <div className='col f-rest o-hide tbl-container '>
              <Button
                disableElevation
                text='Mapping Request'
                color='transparent'
                className='exo2 h5 btn-map'
                onClick={() => {
                  setState((_) => ({ ..._, hideModal: !_.hideModal }))
                }}
                startIcon={<ArrowDropUpIcon className={`h15 arrow rt ${state.hideModal ? '' : 'show'}`} />}
              />
              <div className={`col f-rest collapse ${state.hideModal ? '' : 'show'}`}>
                <div className={`col w-100 h-100 oy-auto hsc`}>
                  {
                    pendingOrgs ?
                      <Table
                        emptyMessage={state.search ? 'No Match' : 'No Mapping Request'}
                        columns={MappingRequestColumns}
                        rows={pendingOrgs}
                        className='w-100 h-100'
                        onAction={handleAcceptRejectMapping} />
                      :
                      <div className='col v-ctr h-ctr w-100 h-100'>
                        <CircularProgress />
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          Boolean(state.confirmMsg) &&
          <ConfirmationBox
            row={true}
            className=''
            btnCancel={{ onClick: handleCancel }}
            btnOK={{
              text: state.confirmMsg.btnOKLabel,
              color: state.confirmMsg.btnOKColor,
              onClick: handleOk
            }}
            subtitleClassName=''
            title={state.confirmMsg.title}
            subtitle={state.confirmMsg.subtitle}
          />
        }
      </div>
  )
}
