import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import {
  OrgMappingScreen, HomeScreen, OrgDetailsScreen, UserScreen, InviteOrgScreen, SSOScreen, InviteUserScreen,
  OrgMainScreen, OrgInternalDetailsScreen, OrgInternalAsmtScreen, OrgInternalModelsScreen, ConfigurationScreen,
  EditOrgScreen, AddOrgScreen, SettingsScreen, RoleBasedComponent, PageNotFoundScreen, CreateUserScreen, EditUserScreen,
  ViewUserScreen, OrgInternalOrgEdit, ProfileScreen, LogoutScreen, OnboardOrgScreen, UpdateExternalUser
} from "../screens";
import { getRoles } from "../store";

const OrgInternalUserRouter = (props) => {
  return (
    <Routes>
      <Route path="/" index element={<UserScreen />} />
      <Route path="/invite" index element={<InviteUserScreen />} />
      <Route path="/new" index element={<CreateUserScreen />} />
      <Route path="/:userId" index element={<ViewUserScreen />} />
      <Route path="/:userId/edit" index element={<EditUserScreen />} />
      <Route path="/*" element={<Navigate replace to="/page-not-found" />} />
    </Routes>
  )
}
const OrgInternalMappingRouter = (props) => {
  return (
    <Routes>
      <Route path="/" index element={<OrgMappingScreen />} />
      <Route path="/invite" index element={<InviteOrgScreen />} />
      <Route path="/*" element={<Navigate replace to="/page-not-found" />} />
    </Routes>
  )
}
const OrgInternalRouter = (props) => {
  const Roles = getRoles();
  return (
    <OrgMainScreen {...props}>
      <Routes>
        <Route path="/info" element={<OrgInternalDetailsScreen />} />
        <Route path="/model" element={<OrgInternalModelsScreen />} />
        <Route path="/asmt" element={<OrgInternalAsmtScreen />} />
        <Route path="/user/*" element={<OrgInternalUserRouter />} />
        <Route path="/mapping/*" element={<OrgInternalMappingRouter />} />
        <Route path="/edit" element={<RoleBasedComponent Component={OrgInternalOrgEdit} notAllowedRoles={[Roles.Architect]} />} />
        <Route path="/sso" element={<SSOScreen isInternal />} />
        <Route path="/config" element={<ConfigurationScreen isInternal />} />
        <Route path="/" index element={<Navigate replace to="info" />} />
        <Route path="/*" element={<Navigate replace to="/page-not-found" />} />
      </Routes>
    </OrgMainScreen>
  )
}

const OrgMainRouter = (props) => {
  const Roles = getRoles();
  return (
    <Routes>
      <Route path="/" index element={<OrgDetailsScreen />} />
      <Route path="/add" element={<OnboardOrgScreen isLoggedIn={true} />} allowedRoles={[Roles.Organiser]} />
      <Route
        path="/edit" index
        element={(
          <RoleBasedComponent Component={EditOrgScreen} allowedRoles={[Roles.Administrator]} />
        )}
      />
      <Route
        path="/create" index
        element={(
          <RoleBasedComponent Component={AddOrgScreen} hideNavBar={true} allowedRoles={[Roles.Administrator]} />
        )}
      />
      <Route
        path="/:orgId/*"
        element={(
          <RoleBasedComponent Component={OrgInternalRouter} allowedRoles={[Roles.Administrator, Roles.Architect]} />
        )}
      />
    </Routes>
  )
}
const MappingRouter = (props) => {
  return (
    <Routes>
      <Route path="/" index element={<OrgMappingScreen />} />
      <Route path="/invite" index element={<InviteOrgScreen />} />
      <Route path="/*" element={<Navigate replace to="/page-not-found" />} />
    </Routes>
  )
}

const UserRouter = (props) => {
  return (
    <Routes>
      <Route path="/" index element={<UserScreen />} />
      <Route path="/invite" index element={<InviteUserScreen />} />
      <Route path="/new" index element={<CreateUserScreen />} />
      <Route path="/:userId/view" index element={<ViewUserScreen />} />
      <Route path="/:userId/edit" index element={<EditUserScreen />} />
      <Route path="/*" element={<Navigate replace to="/page-not-found" />} />
    </Routes>
  )
}

export const MainRouter = (props) => {
  const Roles = getRoles();
  return (
    <HomeScreen {...props}>
      <Routes>
        <Route path="/profile" element={<ProfileScreen />} />
        <Route path="/logout" element={<LogoutScreen />} />
        <Route path="/map-external-user" element={<UpdateExternalUser />} />
        {/* <Route
          path="/M/*" index
          element={(
            <RoleBasedComponent Component={MappingRouter} notAllowedRoles={[Roles.Auditee]} />
          )}
        /> */}
        <Route
          path="/U/*" index
          element={(
            <RoleBasedComponent Component={UserRouter} notAllowedRoles={[Roles.Auditee]} />
          )}
        />
        <Route
          path="/S"
          element={(
            <RoleBasedComponent Component={SSOScreen} notAllowedRoles={[Roles.Auditee]} />
          )}
        />
        <Route
          path="/T" index
          element={(
            <RoleBasedComponent Component={SettingsScreen} allowedRoles={[Roles.Administrator, Roles.Architect]} />
          )}
        />
        <Route path="/O/*" index element={<OrgMainRouter />} />
        <Route path="/" element={<Navigate replace to="/O" />} />
        <Route path="*" element={<Navigate replace to="/page-not-found" />} />
      </Routes>
    </HomeScreen>
  );
}
