import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, } from "react-redux";
import { useParams } from "react-router-dom";
import { AutoCompleteSelect, PrimaryButton } from "../../components";
import { orgActions, getCurrentUserOrg, getNotificationFreqs, getOrgDetailsById } from "../../store";
import { Switch } from "@mui/material";
import "./style.scss";

const getOrgConfig = (org) => {
  return {
    notificationType: org.notification_frequency,
    multiAuth: org.otp_required || false
  }
}
export default (props) => {
  const { isInternal } = props
  const dispatch = useDispatch()
  const { orgId } = useParams();
  const currentOrg = useSelector(getCurrentUserOrg);
  const org = useSelector(getOrgDetailsById.bind(null, orgId));
  const frequencies = useSelector(getNotificationFreqs)
  const [state, setState] = useState({ isEdited: false, config: getOrgConfig((orgId ? org : currentOrg)) });
  useEffect(() => {
    dispatch(orgActions.fetchNotifyFreqList())
  }, [])
  const handleSettingsChange = (e) => {
    const config = { ...state.config };
    let { name, value, checked } = e.target;
    value = name == 'multiAuth' ? checked : value;
    config[name] = value;
    setState((_) => ({ ..._, config }))
  }
  const handleUpdate = () => {
    const config = { multiAuth: state.config.multiAuth, orgId: orgId || currentOrg.id };
    if (state.config.notificationType && state.config.notificationType.id) {
      config.notify = state.config.notificationType.id
    }
    dispatch(orgActions.setOrgConfig(config))
  }
  return (
    <div className={`col  w-100 h-100 o-hide config ${isInternal ? 'internal' : ''}`}>
      <form className='settings-form'>
        <div className='h6 label bold exo2 head'>Notifications</div>
        <div className='col sec'>
          <div className='h7 label med exo2 '>Comments Notification Frequency</div>
          <div className='row form-input'>
            <span className='h8 label med'>Notify</span>
            <AutoCompleteSelect
              value={state.config.notificationType}
              options={frequencies}
              name='notificationType'
              className='h8'
              containerClass='select-group '
              onChange={handleSettingsChange}
            />
          </div>
        </div>
        <div className='h6 label bold exo2 head'>Multi Factor Authentication</div>
        <div className='col sec'>
          <div className='row form-input'>
            <span className='h8 label med'>Enable</span>
            <Switch
              name='multiAuth'
              checked={state.config.multiAuth}
              onChange={handleSettingsChange}
            />
          </div>
        </div>
        <PrimaryButton
          text='Update'
          color='#1177CC'
          className='h4 update'
          onClick={handleUpdate}
          endIcon={(
            <i className={`h4 icon-arrow-round-forward`} />
          )}
        />
      </form>
    </div>
  )
}

