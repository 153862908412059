import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "../../../components";
import { orgActions, getOrgDetailsById } from "../../../store";

const Columns = [
  { label: "Allocated Model", width: '70%', align: 'left', attr: 'title'},
  { label: "Allocated Date", width: '30%', attr: 'created_at' },
]

export default (props) => {
  const { orgId } = useParams();
  const dispatch = useDispatch();
  const org = useSelector(getOrgDetailsById.bind(null, orgId));
  useEffect(() => {
    dispatch(orgActions.fetchOrgAsmtModels({ orgId: orgId }));
  }, [])
  return (
    <div className='org-int-mod w-100 h-100 o-hide'>
      <Table
        columns={Columns}
        className='w-100 h-100 tbl-org'
        rows={org.models}
      />
    </div>
  )
}
