import React from 'react';
import "./style.scss";

const Icon = (props) => {
  return <i className={`${props.icon} ${props.className || ''}`} />
}
export const FileIcon = (props) => {
  return <Icon icon='icon-file-alt' {...props} />
}
export const RightArrowIcon = (props) => {
  return <Icon icon='icon-arrow-forward' {...props} />
}
export const DownArrowIcon = (props) => {
  return <Icon icon='icon-chavron-down' {...props} />
}
export const PlusIcon = (props) => {
  return <Icon icon='icon-plus' {...props} />
}
export const SearchIcon = (props) => {
  return <Icon icon='icon-search' {...props} />
}
export const ArrowDropUpIcon = (props) => {
  return <Icon icon='icon-arrow-dropup' {...props} />
}
export const CheckmarkIcon = (props) => {
  return <Icon icon='icon-checkmark' {...props} />
}
export const BackIcon = (props) => {
  return <Icon icon='icon-back' {...props} />
}

