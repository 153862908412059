import createSagaMiddleware from 'redux-saga';
import { ReducerRegistry, SagaRegistry } from "./common";
import { configureStore } from "@reduxjs/toolkit";

export function createAppStore() {
  const saga = createSagaMiddleware();
  const rootReducer = ReducerRegistry.combineReducers();
  const rootSaga = SagaRegistry.getAllSagas();
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({thunk: false, serializableCheck:false}).concat(saga)
    }
  })
  saga.run(rootSaga)
  return store;
}
export * from "./common";
export * from "./user";
export * from "./assessment";
export * from "./organization";