import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Input, PrimaryButton } from "../../components";
import { isUserInvited, userActions, getCurrentUserOrg, getExternalUserInviteStatus, orgActions } from "../../store";
import "./style.scss";

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const org = useSelector(getCurrentUserOrg)
  const { orgId } = useParams();
  const [state, setState] = useState({ email: '', invited: false });
  const inviteStatus = useSelector(getExternalUserInviteStatus)
  useEffect(() => {
    if (inviteStatus) {
      if (!inviteStatus.isOrgExist || !inviteStatus.isMappingExist) {
        dispatch(orgActions.isOrgUserExistSuccess({ ...inviteStatus, email: state.email }))
        return navigate('/M/invite')
      }
    }
  }, [inviteStatus])
  const onSubmit = (e) => {
    e.preventDefault();
    if (Boolean(inviteStatus)) {
      navigate(0);
      return;
    }
    if (!state.email) {
      return setState((_) => ({ ..._, error: 'required' }))
    }
    dispatch(userActions.inviteExternalUser({ email: state.email, currentUserOrg: org.id, orgId: orgId }))
  }
  return (
    <div className='col w-100 h-100 user-map-scr usr-invite'>
      <Grid container>
        <Grid item xs={12} sm={12} lg={6} md={6} >
          <h3 className='h5 exo2 med title'>Invite External User</h3>
          <form className='form' onSubmit={onSubmit}>
            <Input
              label="External User's Email Address"
              labelClassName='h11'
              className='h7'
              placeholder='email@domain.com'
              value={state.email}
              disabled={Boolean(inviteStatus)}
              error={state.error}
              onChange={(e) => {
                setState((_) => ({ ..._, email: e.target.value, error: '' }))
              }}
            />
            {
              Boolean(inviteStatus) &&
              <div className='col suc-card blink_me'>
                {
                  Boolean(inviteStatus.user) ?
                    <>
                      {
                        inviteStatus.user.is_disabled ?
                          <div className='h10 error'>Invited User is inactive</div>
                          : (inviteStatus.isUserMapped !== undefined) ?
                            <div className='h5 success'>{inviteStatus.isUserMapped ? 'User is already Mapped !' : 'User Mapping pending'}</div>
                            :
                            <div className='h5 success'>Request submitted!</div>
                      }
                    </>
                    :
                    <div className='h10 note'>Invited User Not Exist, Create User Invitation has been sent <span className='bold'>{state.email}</span>.</div>
                }
              </div>
            }
            <PrimaryButton text={Boolean(inviteStatus) ? 'Okay' : "Invite"} onClick={onSubmit} type={Boolean(inviteStatus) ? 'button' : "submit"} />
          </form>
        </Grid>
      </Grid>

    </div>
  )
}
