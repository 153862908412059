import { useNavigate } from "react-router-dom";
export const isNavHistoryExist = () => {
  return window.history && window.history.state
}
export const useNavigateBack = () => {
  const navigate = useNavigate();
  const navigateBack = () => {
    if (isNavHistoryExist()) {
      navigate(-1)
    } else {
      navigate('/')
    }
  }
  return navigateBack
}
