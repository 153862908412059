import "./saga";
import { isAdmin, isArchitect, isOrganizer } from "./helpers";
export * from "./slice";
export * from "./helpers";

const filterProps = ["name", "email", "department_name", "roleName"]

const getUserState = (state) => state.user;

const filterUserHelper = (users, search) => {
  if (Array.isArray(users) && search) {
    return users.filter((user) => {
      return filterProps.filter(prop => {
        return String(user[prop]).includes(search)
      }).length > 0;
    })
  } else {
    return users;
  }
}

export const getLogInStatus = (state) => {
  let userState = getUserState(state);
  return userState.logInStatus
}
export const getOTPStatus = (state) => {
  let userState = getUserState(state);
  return userState.otpstatus
}
export const isUserInvited = (state) => {
  let userState = getUserState(state);
  return userState.isUserInvited;
}
export const getNewJoinUser = (state) => {
  let userState = getUserState(state);
  return userState.newUser;
}
export const getOrgActiveUsers = (orgId, search, state) => {
  let userState = getUserState(state);
  let users = userState.activeUsers[orgId];
  return filterUserHelper(users, search);
}
export const getOrgInActiveUsers = (orgId, search, state) => {
  let userState = getUserState(state);
  let users = userState.inActiveUsers[orgId];
  return filterUserHelper(users, search);
}
export const getUserMappings = (state) => {
  let userState = getUserState(state);
  return userState.orgUserMappings;
}

export const isLoggedOut = (state) => {
  let userState = getUserState(state);
  return userState.isLoggedOut;
}
export const getUserActivatedOrDeactivatedStatus = (state) => {
  let userState = getUserState(state);
  return userState.isUserActivatedOrDeactivated;
}
export const getExternalUserInviteStatus = (state) => {
  let userState = getUserState(state);
  return userState.externalUserInvite
}
export const isUserCreated = (state) => {
  let userState = getUserState(state);
  return userState.isUserCreated
}
export const getCountryCodes = (state) => {
  let userState = getUserState(state);
  return userState.countryCodes
}
export const getRolesIdsList = (state) => {
  let userState = getUserState(state);
  return userState.roles || [];
}
export const getRoleById = (list, rolId) => {
  return list.find(({ id }) => id === rolId)
}
export const getRolesList = (currentUserRole, state) => {
  const roles = getRolesIdsList(state);
  const cUser = { role: { name: currentUserRole } }
  return roles.filter(({ id, idStr }) => {
    let tempUserRole = { role: { name: idStr } }, res = true;
    if (isAdmin(tempUserRole) && !isAdmin(cUser)) {
      res = false;
    } else if (isArchitect(tempUserRole) && !isAdmin(cUser) && !isArchitect(cUser)) {
      res = false;
    } else if (!isAdmin(cUser) && !isArchitect(cUser) && !isOrganizer(cUser) && isOrganizer(tempUserRole)) {
      return false
    }
    return res
  });
}
export const getFetchedUser = (state) => {
  let userState = getUserState(state);
  return userState.fetchedUser
}
export const getPasswordChangeStatus = (state) => {
  let userState = getUserState(state);
  return userState.passwordStatus
}
