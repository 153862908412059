import React from 'react';
import { Provider } from "react-redux";
import ConsoleRouter from "./routers";
import { createAppStore } from "./store";
import './App.scss';

function App() {
  return (
    <Provider store={createAppStore()}>
      <ConsoleRouter />
    </Provider>
  );
}

export default App;
