import { createSlice } from "@reduxjs/toolkit";
import { ReducerRegistry } from "../common";

const initialState = {
  statusList: null,
  assessments: {},
  updatedAsmt: null
}

const assessment = createSlice({
  name: "assessment",
  initialState: initialState,
  reducers: {
    fetchStatusList: () => { },
    fetchStatusListSuccess: (state, action) => {
      state.statusList = action.payload;
    },
    fetchAssessment: () => { },
    fetchAssessmentSuccess: (state, action) => {
      const asmt = { ...state.assessments, [action.payload.id]: action.payload }
      state.assessments = { ...asmt }
    },
    updateAsmtStatus: () => {},
    updateAsmtStatusSuccess: (state, action) => {
      state.updatedAsmt = action.payload
    }
  }
})
export const assessmentActions = assessment.actions;

ReducerRegistry.register("assessment", assessment.reducer)