import { createSlice } from "@reduxjs/toolkit";
import { ReducerRegistry } from "../common";

export const KEY_NM_API = 'NM-API-Key';
export const AUDIT_NM_API = 'AUDIT-API-Key';

const initialState = {
  currentUser: null,
  inActiveUsers: {},
  activeUsers: {},
  otpstatus: false
}

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    getCurrentUser: (state) => {
      state.currentUser = null
    },
    login: (state, action) => {
      state.logInStatus = 'wait';
      state.loggedOut = false;
    },
    sendAuthOTP: () => {},
    checkForMultiFactorAuth: () => {},
    logout: (state, action) => { },
    validateToken: (state) => { },
    inviteUser: state => { },
    fetchUser: () => { },
    inviteExternalUser: state => { },
    fetchRoles: () => { },
    fetchCountryCodes: () => { },
    fetchResetTokenUser: state => { },
    resetNewUserPassword: state => { },
    fetchUserMappings: state => { },
    fetchActiveUsers: state => { },
    fetchInActiveUsers: state => { },
    acceptOrRejectUserMapping: state => { },
    setOTPStatus: (state, action) => {
      state.otpstatus = action.payload
    },
    logInSuccess: (state, action) => {
      state.currentUser = action.payload.current_ent_usr;
      state.orgLogo = action.payload.ent_org_logo;
      state.logInStatus = action.payload.status
    },
    logInSuccessClear: (state, action) => {
      state.logInStatus = null
    },
    toggleActivate: () => { },
    logInError: (state, action) => {
      state.logInStatus = { status: false };
    },
    inviteUserSuccess: (state, action) => {
      state.isUserInvited = action.payload;
    },
    getResetTokenUserSuccess: (state, action) => {
      state.newUser = action.payload;
    },
    resetNewUserPasswordSuccess: (state, action) => {
      let newuser = action.payload;
      newuser.resetPasswordSuccess = true;
      state.newUser = newuser;
    },
    fetchActiveUsersSuccess: (state, action) => {
      const { orgId, users } = action.payload
      state.activeUsers = { ...state.activeUsers, [orgId]: users };
    },
    fetchInactiveUsersSuccess: (state, action) => {
      const { orgId, users } = action.payload
      state.inActiveUsers = { ...state.inActiveUsers, [orgId]: users };
    },
    fetchUserMappingsSuccess: (state, action) => {
      state.orgUserMappings = action.payload
    },
    logoutSuccess: (state, action) => {
      state.isLoggedOut = true;
    },
    toggleActivateSuccess: (state, action) => {
      state.isUserActivatedOrDeactivated = action.payload;
    },
    clear: (state,) => {
      state.isLoggedOut = false;
      state.currentUser = null;
      state.logInStatus = false;
      state.inActiveUsers = {};
      state.activeUsers = {};
    },
    inviteExternalUserSuccess: (state, action) => {
      state.externalUserInvite = action.payload;
    },
    createUser: () => { },
    updateUser: () => { },
    createUserSuccess: (state, action) => {
      state.isUserCreated = action.payload
    },
    fetchRolesSuccess: (state, action) => {
      state.roles = action.payload
    },
    fetchCountryCodesSuccess: (state, action) => {
      state.countryCodes = action.payload
    },
    fetchUserSuccess: (state, action) => {
      state.fetchedUser = action.payload
    },
    sendPasswordLink: () => { },
    sendPasswordLinkSuccess: (state, action) => {
      state.passwordStatus = action.payload
    },
    changePassword: () => { },
  }
})
export const getCurrentKey = () => {
  return localStorage.getItem(KEY_NM_API);
}
export const getModuleSwitchKey = () => {
  return localStorage.getItem(AUDIT_NM_API);
}
export const userActions = userSlice.actions;

ReducerRegistry.register("user", userSlice.reducer)