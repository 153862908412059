import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { assessmentActions, getStatusList, orgActions, getOrgDetailsById, getUpdatedAsmt } from "../../../store";
import { useParams } from "react-router-dom";
import { Table } from "../../../components";
import AssessmentDialog from "./AssessmentDialog";

const Columns = [
  { label: "Assessment", width: '35%', align: 'left', attr: 'title' },
  { label: "Status", width: '12%', attr: 'asmt_status', input: true, type: "select", list: [] },
  {
    label: "Controls", width: '33%', attr: 'controls',
    children: [
      {
        label: "Response", attr: 'asmt_status_details',
        children: [
          { label: "Pending", attr: 'response_pending_count' },
          { label: "Completed", attr: 'response_completed_count' }
        ]
      },
      {
        label: "Evaluation", attr: 'asmt_status_details',
        children: [
          { label: "Pending", attr: 'evaluation_pending_count' }, { label: "Completed", attr: 'evaluation_completed_count' }
        ]
      },
    ]
  },
  { label: "Total", width: '10%', attr: 'total_cntrls' },
  { label: "Action", width: '10%', type: 'actions', actions: [{ label: 'Details', action: 'edit' }] },
]

export default (props) => {
  const { orgId } = useParams();
  const dispatch = useDispatch();
  const statusList = useSelector(getStatusList);
  const org = useSelector(getOrgDetailsById.bind(null, orgId));
  const updated = useSelector(getUpdatedAsmt);
  const [state, setState] = useState({ asmtId: null })
  useEffect(() => {
    dispatch(assessmentActions.fetchStatusList());
    dispatch(orgActions.fetchOrgAsmtModels({ orgId: orgId }));
  }, [])
  useEffect(() => {
    if (updated) {
      dispatch(orgActions.setModelStatus({ asmt: updated, orgId: orgId }));
      dispatch(assessmentActions.updateAsmtStatusSuccess(null));
    }
  }, [updated])
  const handleOrgCellClick = (action, row) => {
    setState((_) => ({ ..._, asmtId: row.id }))
  }
  const handleStatusChange = (e, asmt) => {
    const { value } = e.target
    const payload = { asmt_id: asmt.id, ent_org_id: orgId, state: value.id }
    dispatch(assessmentActions.updateAsmtStatus(payload));
  }
  const columns = Columns.map((_) => {
    const _new = { ..._ }
    if (_.attr === 'asmt_status') {
      _new.list = (statusList || []);
      _new.onFieldInput = handleStatusChange
    }
    return _new
  })
  return (
    <div className='org-int-det w-100 h-100 oy-auto'>
      <Table
        columns={columns}
        className='w-100 h-100 tbl-asmt'
        rows={org.asmts}
        onAction={handleOrgCellClick}
        onInputChange={handleStatusChange}
      />
      {
        state.asmtId &&
        <AssessmentDialog asmtId={state.asmtId} onClose={() => handleOrgCellClick(null, { id: null })} />
      }
    </div>
  )
}

