import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Grid, CircularProgress } from "@mui/material";
import { Screen, HelpSection, useNavigateBack } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { isLoading, getCurrentUser, userActions, isUserCreated, getFetchedUser } from "../../store";
import { UserForm } from "../Form";
import "./style.scss";

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateBack = useNavigateBack();
  const { userId } = useParams();
  const currentUser = useSelector(getCurrentUser)
  const loading = useSelector(isLoading);
  const isCreated = useSelector(isUserCreated);
  const [searchParams] = useSearchParams();
  const user = useSelector(getFetchedUser);
  const getUserId = () => {
    if (userId) {
      return userId
    }
    return searchParams.get("id")
  }
  useEffect(() => {
    const userId = getUserId();
    if (!userId) {
      return navigate("/page-not-found")
    }
    dispatch(userActions.fetchUser({ userId }))
  }, [])
  useEffect(() => {
    if (user === "NotFound") {
      navigate('/page-not-found')
    }
  }, [user])
  useEffect(() => {
    if (isCreated) {
      if (props.showCancel === undefined) {
        handleCancel()
      }
    }
  }, [isCreated])
  const handleCancel = () => {
    dispatch(userActions.createUserSuccess(false))
    navigateBack()
  }
  const onSubmit = (formData) => {
    const userId = getUserId()
    const body = { ent_usr: formData, user_id: Number(userId) }
    dispatch(userActions.updateUser(body))
  }
  return (
    <div className='col w-100 h-100 o-hide'>
      <Screen className='user-add col f-rest o-hide'>
        <Grid container spacing={0} classes={{root: 'o-hide'}}>
          <Grid item xs={12} sm={12} md={9} lg={8} classes={{ root: 'col h-100  o-hide' }} direction="column">
            {
              (user && user !== 'NotFound') ?
                <>
                  <h1 className='exo2 h5 med page-head'>Update User</h1>
                  <UserForm
                    nonEditable={['email']}
                    currentUser={currentUser}
                    btnSubmit='Update' btnReset='Back' user={user} showCancel={props.showCancel}
                    onSubmit={onSubmit} disabled={loading} onCancel={handleCancel} />
                </>
                :
                <CircularProgress />
            }
          </Grid>
          <HelpSection xs={12} sm={12} md={3} lg={4} />
        </Grid>
      </Screen>
    </div>
  )
}