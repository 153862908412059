import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress, Grid } from '@mui/material';
import { Button, Screen, BackIcon, PrimaryButton, NavItems, AppErrorBoundry, ConfirmationBox } from "../../components";
import { getOrgDetailsById, orgActions, isAdmin, getCurrentUser } from "../../store";

import { connect } from "react-redux";
import "./style.scss";

const OrgNavItems = [
  { link: 'info', label: 'Information' },
  { link: 'model', label: 'Model' },
  { link: 'asmt', label: 'Assessment' },
  // { link: 'mapping', label: 'Mapping' },
  { link: 'user', label: 'User' },
  { link: 'sso', label: 'SSO' },
  { link: 'config', label: 'Configuration' },
]
const OrgMainScreen = (props) => {
  const { orgId } = useParams()
  const from = useRef(null)
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const org = useSelector(getOrgDetailsById.bind(null, orgId));
  useEffect(() => {
    dispatch(orgActions.fetchOrgById({ orgId }))
    from.current = location.state && location.state.from
  }, []);
  useEffect(() => {
    if (org === 'NotFound') {
      return navigate("/page-not-found")
    }
  }, [org]);
  
  const handleBack = () => {
    if (from && from.current) {
      navigate("/" + from.current)
    } else {
      navigate('/')
    }
  }
  return (
    <Screen className='org-main col w-100 h-100 o-hide'>
      {
        !Boolean(org) || (org === 'NotFound') ?
          <div className='full col v-ctr h-ctr loading w-100 h-100'>
            <CircularProgress />
          </div>
          :
          <>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                {
                  Boolean(org) &&
                  <>
                    <div className='row w-100 h-btn v-start'>
                      <Button
                        text={org.brandname}
                        disableElevation font='h5' className="exo2 back-btn"
                        startIcon={<BackIcon className='back-icon' />}
                        onClick={handleBack}
                      />
                    </div>
                    <div className={`row navbar w-100`}>
                      <div className={`items h-btn w-100`}>
                        <NavItems items={OrgNavItems} font='h5' />
                      </div>
                    </div>
                  </>
                }
              </Grid>
              <Grid xs={12} sm={12} md={4} lg={4} item />
            </Grid>
            <div className='col o-hide f-rest org-int-ro'>
              <div className='col f-rest o-hide'>

                <AppErrorBoundry>
                  {props.children}
                </AppErrorBoundry>

              </div>
            </div>
          </>
      }
    </Screen>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {

  }
}
const mapStateToProps = (state) => {
  return {

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrgMainScreen);
