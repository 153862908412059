import React from 'react';
import "./style.scss";
import { LoadingOval, LoadingGIF } from "../Assets"

export class AppErrorBoundry extends React.Component {
  state = { hasError: false }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo)
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className='col full w-100 h-100 flex-ctr'>
          <h1>{this.props.message || 'Something went wrong. Please check logs'}</h1>
        </div>
      );
    }
    return this.props.children;
  }
}

export const Screen = (props) => {
  const { className } = props;
  return (
    <div className={'col full w-100 h-100 home ' + (className || '')}>
      {props.children}
    </div>
  )
}
export const OvalLoading = (props) => {
  return (
    <div className={`col w-100 h-100 h-ctr v-ctr loading-indicator ${props.isSmall ? 'small' : ''}`}>
      <LoadingOval className="loading-oval" />
      <div className="row h-ctr v-ctr">
        <LoadingGIF className="loading" />
        <span className={`exo2 ${props.isSmall ? 'h10' : 'h4'}`}>{props.message || 'Please Wait...'}</span>
      </div>
    </div >
  )
}


