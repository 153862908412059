import React, { useEffect, useState } from 'react';
import { HelpSection, Screen, Input, LinkButton, PrimaryButton, RightArrowIcon, ConfirmationBox, useNavigateBack, Button } from "../../components";
import { useNavigate, } from "react-router-dom";
import { Grid, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { userActions, getCurrentUser, getFetchedUser, isLoading, getRoles, getPasswordChangeStatus } from "../../store";
import { UserForm } from "../Form";
import "./style.scss";

const ChangePasswordForm = [
  { label: "Current Password", name: "current" },
  { label: "New Password", name: "new" },
  { label: "Confirm Password", name: "confirm" },
]

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateBack = useNavigateBack()
  const cuser = useSelector(getCurrentUser);
  const user = useSelector(getFetchedUser);
  const Roles = getRoles()
  const loading = useSelector(isLoading)
  const status = useSelector(getPasswordChangeStatus);
  const [state, setState] = useState({ tab: 'personal', error: {}, resetForm: {}, message: null, profileMode: 'view' });
  useEffect(() => {
    if (!user) {
      dispatch(userActions.fetchUser({ userId: cuser.id }))
    }
  }, [user])
  useEffect(() => {
    if (status) {
      const confirmMsg = {
        title: "Password Changed Successfully",
        btnOKLabel: 'Okay',
        subtitle: ["Please relogin with new password"]
      }
      setState((_) => ({ ..._, message: confirmMsg }))
    }
  }, [status])
  const handleSave = (formData) => {
    if (state.profileMode === 'edit') {
      const body = { ent_usr: formData, user_id: Number(cuser.id) }
      dispatch(userActions.updateUser(body))
    }
    setState((_) => ({ ..._, profileMode: _.profileMode === 'view' ? 'edit' : 'view' }))
  }
  const setTab = (tab) => {
    if (tab !== state.tab) {
      setState((_) => ({ ..._, tab: tab }))
    }
  }
  const handlePasswordInput = (e) => {
    const { name, value } = e.target, error = { ...state.error }, formData = { ...state.resetForm };
    formData[name] = value;
    error[name] = false;
    setState((_) => ({ ..._, error: error, resetForm: formData }))
  }
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const { resetForm } = state, error = {};
    ChangePasswordForm.forEach((_) => {
      let valExist = Boolean(resetForm[_.name] ? String(resetForm[_.name]).trim() : resetForm[_.name]);
      if (!valExist) {
        error[_.name] = 'req'
      }
    });
    if (resetForm.new && resetForm.confirm && resetForm.new !== resetForm.confirm) {
      error.confirm = 'Password Missmatch'
    }
    if (Object.keys(error).length > 0) {
      return setState((_) => ({ ..._, error }))
    }
    const confirmMsg = {
      title: "Change Password",
      btnOKLabel: 'Change',
      btnOKColor: '#FF3333',
      btnCancel: { onClick: handleCancel },
      subtitle: ["Are you sure to change the password?. Change Password requires relogin"]
    }
    setState((_) => ({ ..._, message: confirmMsg }))
  }
  const handleOk = () => {
    handleCancel();
    if (status) {
      dispatch(userActions.sendPasswordLinkSuccess(false))
      dispatch(userActions.clear())
      navigate("/login")
    } else {
      const body = {
        ent_usrs: {
          password: state.resetForm.new,
          password_confirmation: state.resetForm.confirm,
          current_password: state.resetForm.current,
          ent_usr_id: cuser.id
        }
      }
      dispatch(userActions.changePassword(body))
    }
  }
  const handleCancel = () => {
    setState((_) => ({ ..._, message: null }))
  }
  const handleBack = () => {
    if (state.profileMode === 'edit') {
      setState((_) => ({ ..._, profileMode: 'view' }))
    } else {
      navigate("/")
    }
  }
  return (
    <div className='col w-100 h-100 profile-screen'>
      <div className='row'>
        <LinkButton label="Personal Information" className={`h6 exo2 ${state.tab === 'password' ? 'deselect' : ''}`} onClick={() => setTab('personal')} />
        <LinkButton label="Change Password" className={`h6 tab exo2 ${state.tab === 'personal' ? 'deselect' : ''}`} onClick={() => setTab('password')} />
      </div>
      <Screen className='user-add col f-rest o-hide'>
        <Grid container spacing={0} classes={{root: 'o-hide'}}>
          <Grid item xs={12} sm={12} md={9} lg={9} classes={{ root: 'col h-100  o-hide' }} >
            {
              state.tab === 'personal' ?
                Boolean(user) ?
                  <UserForm
                    readOnly={state.profileMode === 'view'}
                    nonEditable={['email', 'role_id']}
                    btnSubmit={state.profileMode === 'view' ? 'Edit' : 'Save'}
                    btnReset={state.profileMode === 'view' ? 'Back' : 'Cancel'}
                    user={user}
                    onCancel={handleBack}
                    currentUser={{ role: { name: Roles.Administrator } }}
                    onSubmit={handleSave} disabled={loading} />
                  :
                  <div className='col h-crt v-ctr w-100 h-100'>
                    <CircularProgress />
                  </div>
                :
                <form className='pass-form' onSubmit={handleFormSubmit} noValidate>
                  <Grid container columnSpacing={4} direction="row" justifyContent="space-between" alignItems="flex-end">
                    {
                      ChangePasswordForm.map((_) => {
                        return (
                          <Grid item xs={12} sm={12} md={9} lg={9} key={_.name}>
                            <Input
                              {..._}
                              className='h8'
                              type="password"
                              value={state.resetForm[_.name] || ''}
                              error={state.error[_.name] || false}
                              onChange={handlePasswordInput}
                            />
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                  <div className='row v-ctr '>
                    <PrimaryButton text="Change" type="submit" endIcon={<RightArrowIcon />} />
                    <Button text='Cancel' type='button' className='h8 med reset-btn' onClick={handleBack} />
                  </div>
                </form>
            }
          </Grid>
          <HelpSection xs={12} sm={12} md={3} lg={3} />
        </Grid>
      </Screen>
      {
        Boolean(state.message) &&
        <ConfirmationBox
          row={true}
          className=''
          btnCancel={state.message.btnCancel}
          btnOKColor={state.message.btnOKColor}
          btnOK={{ text: state.message.btnOKLabel, onClick: handleOk }}
          subtitleClassName=''
          title={state.message.title}
          subtitle={state.message.subtitle}
        />
      }
    </div>
  )
}

