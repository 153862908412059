import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { LogInScreen, JoinNowScreen, ForgetPassword, OnboardOrgScreen, PageNotFoundScreen } from "../screens";
import { MainRouter } from "./main.router";

function AppRouter() {
  return (
    <BrowserRouter >
      <Routes>
        <Route path="" element={<Navigate to="/O" />} />
        <Route path="/login" element={<LogInScreen />} exact />
        <Route path="/org-onboard" element={<OnboardOrgScreen />} />
        <Route path="/reset-password" element={<JoinNowScreen />} />
        <Route path="/forgot-password" element={<ForgetPassword />} />
        <Route path="/page-not-found" element={<PageNotFoundScreen />} />
        <Route path="/*" element={<MainRouter />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
