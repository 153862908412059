import { put, takeEvery } from "redux-saga/effects";
import { SagaRegistry, requestHandler, getDateInFormat, setAlertAndLoading } from "../common";
import { axios } from "../../utils/axios";
import { assessmentActions } from "./slice";

function* fetchStatusList() {
  const res = yield axios.get("/consoles/asmt_status_list");
  const list = (res.asmt_status_list || []).map((_) => ({ id: String(_).toLowerCase(), label: _ }));
  yield put(assessmentActions.fetchStatusListSuccess(list));
}
function* fetchAssessment({ payload }) {
  yield requestHandler(function* () {
    const res = yield axios.post("/consoles/asmt_detail", { asmt_id: payload.asmtId })
    let asmt = res.asmt_detail;
    if (asmt) {
      asmt.start_date = asmt.start_date ? getDateInFormat(asmt.start_date) : asmt.start_date;
      asmt.end_date = asmt.end_date ? getDateInFormat(asmt.end_date) : asmt.end_date;
      asmt.actual_start_date = asmt.actual_start_date ? getDateInFormat(asmt.actual_start_date) : asmt.actual_start_date;
      asmt.actual_end_date = asmt.actual_end_date ? getDateInFormat(asmt.actual_end_date) : asmt.actual_end_date;
    }
    yield put(assessmentActions.fetchAssessmentSuccess(asmt ? { ...asmt, id: payload.asmtId } : asmt))
  })
}
function* updateAsmtStatus({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(null, null, true)
    const { asmt } = yield axios.post("/consoles/update_asmt_state", payload)
    yield put(assessmentActions.updateAsmtStatusSuccess())
    let message = {
      title: `Assessment Status Updated`,
      subtitle: "The changes made to the Assessment have been saved successfully!"
    }
    yield put(assessmentActions.updateAsmtStatusSuccess(asmt))
    yield setAlertAndLoading(null, message, false);
  })
}

SagaRegistry.register(function* assessmentSaga() {
  yield takeEvery("assessment/fetchStatusList", fetchStatusList)
  yield takeEvery("assessment/fetchAssessment", fetchAssessment)
  yield takeEvery("assessment/updateAsmtStatus", updateAsmtStatus)
})