import React from "react";
import "./style.scss";

const TabBtn = (props) => {
  const { label, active, ...rest } = props
  return (
    <button className={`tab-btn ${active ? 'active' : ''}`} {...rest}>
      <span>{label}</span>
    </button>
  )
}
const TabBtnDivider = (props) => {
  return (
    <div className='row f-rest'>
      <div className='col w-100 tab-divider'></div>
    </div>
  )
}
export const PaginationTab = (props) => {
  const { items, activeKey, onTabchange } = props;
  return (
    Array.isArray(items) && items.length > 0 &&
    <div className='row w-100 pagination-tab'>
      {
        items.map((item, i) => {
          return (
            <React.Fragment>
              <TabBtn
                label={item.label}
                active={item.key === activeKey}
                onClick={onTabchange}
              />
              {
                Boolean(i < (items.length - 1)) &&
                <TabBtnDivider />
              }
            </React.Fragment>
          )
        })
      }
    </div>
  )
}