import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { orgActions, isOrgCreateSuccess, isLoading, getNewJoinUser, getRoles, userActions, KEY_NM_API, getRolesIdsList, getCurrentUser } from "../../store";
import { AppNavBar, Screen, HelpSection, ConfirmationBox } from "../../components";
import { OrgForm } from "./Components/Form";
import { UserForm } from "../Form";
import { OrganiserForm } from "./Components/Organiser";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const _formData = useRef(null)
  const loading = useSelector(isLoading);
  const user = useSelector(props.isLoggedIn ? getCurrentUser : getNewJoinUser);
  const RolesConstant = getRoles()
  const RolesIds = useSelector(getRolesIdsList)
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState({ showConfirm: false, showCreateOrganiser: false })
  const isCreated = useSelector(isOrgCreateSuccess);
  useEffect(() => {
    if (props.isLoggedIn) {
      if (user) {
        if (user.ent_org) {
          return navigate("/")
        }
        return
      }
    }
    document.title = 'Console';
    const token = searchParams.get('token');
    if (token) {
      dispatch(userActions.fetchResetTokenUser({ token: token }))
    } else {
      navigate("/login")
    }
  }, [])
  useEffect(() => {
    if (user) {
      if (user === 'Invalid') {
        return navigate("/page-not-found")
      }
      const header = { [KEY_NM_API]: user.api_key }
      dispatch(userActions.fetchRoles({ header }));
      dispatch(userActions.fetchCountryCodes({ header }));
      dispatch(orgActions.fetchCountryList({ header }));
      dispatch(orgActions.fetchIndustryList({ header }));
      dispatch(orgActions.fetchDepartments({ header }));
      dispatch(orgActions.fetchDesignations({ header }))
    }
  }, [user])
  useEffect(() => {
    if (isCreated) {
      dispatch(orgActions.createOrUpdateOrgStatus({ create: false, updated: false }));
      setState((_) => ({ ..._, showConfirm: true }));
    }
  }, [isCreated])
  const onSubmit = (formData, json) => {
    _formData.current = { formData, json }
    setState((_) => ({ ..._, showCreateOrganiser: true }));
    // dispatch(orgActions.creatOrUpdateOrg(formData));
  }
  const onOk = () => {
    setState((_) => ({ ..._, showConfirm: false }));
    navigate('/')
  }
  const handleOrganiserSave = (formData) => {
    const header = { [KEY_NM_API]: user.api_key }
    const organiser = { ent_usr: formData, user_id: Number(user.id) }
    const body = { organiser: organiser, org: _formData.current.formData, onboard: true, header: header };
    dispatch(orgActions.creatOrUpdateOrg(body));
  }
  const showNavBar = props.hideNavBar === true ? false : true
  return (
    <div className='col w-100 h-100 o-hide'>
      {
        Boolean(user) && Boolean(RolesIds) &&
        <>
          {
            showNavBar && <AppNavBar items={[]} user={null} />
          }
          <div className='org-add col f-rest o-hide'>
            <Grid container spacing={0} classes={{ root: 'o-hide h-100' }}>
              <Grid item xs={12} sm={12} md={9} lg={9} classes={{ root: 'o-hide col h-100' }} style={{ flexDirection: "column" }}>
                <h1 className='exo2 h5 med page-head'>{state.showCreateOrganiser ? `Create Organiser ${_formData.current && _formData.current.json && _formData.current.json.brandname ? `for ${_formData.current.json.brandname}` : ''}` : 'Provide your Organisation details'}</h1>
                {
                  state.showCreateOrganiser ?
                    <UserForm
                      user={{
                        email: user.email,
                        role_id: user.role_id
                      }}
                      btnSubmit="Create Org and Organiser"
                      nonEditable={['email', 'role_id']}
                      hiddenFields={['dept_lead']}
                      onSubmit={handleOrganiserSave}
                      currentUser={{ role: { name: RolesConstant.Administrator } }}
                      onReset={() => setState((_) => ({ ..._, showCreateOrganiser: false }))}
                      disabled={loading} />
                    :
                    <OrgForm btnSubmit="Continue to Add Organiser" onSubmit={onSubmit} disabled={loading} hideReset={true} />
                }
              </Grid>
              <HelpSection xs={12} sm={12} md={3} lg={3} />
            </Grid>
          </div>
          {
            state.showConfirm &&
            <ConfirmationBox
              row={true}
              btnOK={{
                text: 'Okay',
                onClick: onOk
              }}
              className='org-add-success'
              subtitleClassName=''
              title='Submission Successful'
              subtitle={[
                `Your Organisation Details have been submitted successfully.`
              ]}
            />
          }
        </>
      }
    </div>
  )
}
