import React from 'react';
import { PrimaryButton } from "../Button";
import { Eval404 } from "../Assets";
import { Grid, Button, Divider } from "@mui/material";
import "./style.scss";

export const PageNotFound = (props) => {
  return (
    <div className='full w-100 h-100 col page-not-found'>
      <Grid container classes={{root: 'h-100'}}>
        <Grid item xs={12} sm={12} md={6} lg={6} >
          <div className='col oval-container text-container'>
            <div className='exo2 txt clang italic'>CLANG!</div>
            <div className='bold txt txt-404'>Page Not Found</div>
            <p className='txt-info'>What you're looking for might be renamed, <br />removed, or might not exist!</p>
            <PrimaryButton disableElevation className='h10 back-home' text='Back to Home Page' showIcon={false} onClick={props.onHome} />
          </div>
        </Grid>
        <Eval404 alt='Oval-Not-Found' className='oval-404' />
      </Grid>
      {/* <div className='flex-ctr row term-container'>
        <Button disableElevation disableRipple className='h5 exo2 btn-back' classes={{ text: 'text' }}>Terms & Conditions</Button>
        <Divider orientation="vertical" classes={{ root: 'divider' }} />
        <Button disableElevation disableRipple className='h5 exo2 btn-back' classes={{ text: 'text' }}>Privacy Policy</Button>
      </div> */}
    </div>
  )
}