import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { Screen, HelpSection, useNavigateBack } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { isLoading, getCurrentUserOrg, getCurrentUser, userActions, isUserCreated, getOrgDetailsById } from "../../store";
import { UserForm } from "../Form";
import "./style.scss";

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateBack = useNavigateBack();
  const loading = useSelector(isLoading);
  const { orgId } = useParams();
  const currentOrg = useSelector(getCurrentUserOrg)
  const org = useSelector(orgId ? getOrgDetailsById.bind(null, orgId) : getCurrentUserOrg);
  const isCreated = useSelector(isUserCreated);
  const currentUser = useSelector(getCurrentUser)
  useEffect(() => {
    if (!org) {
      navigate('/page-not-found')
    }
  }, [org])
  useEffect(() => {
    if (isCreated) {
      handleCancel()
    }
  }, [isCreated])
  const handleCancel = () => {
    dispatch(userActions.createUserSuccess(false))
    navigateBack()
  }
  const onSubmit = (formData) => {
    formData.ent_org_id = org.id;
    const body = { ent_usr: formData }
    dispatch(userActions.createUser(body))
  }
  return (
    <div className='col w-100 h-100 o-hide'>
      <Screen className='user-add col f-rest o-hide'>
        <Grid container spacing={0} classes={{root: 'o-hide'}} >
          <Grid item xs={12} sm={12} md={9} lg={8} classes={{ root: 'col h-100  o-hide' }} direction="column">
            <h1 className='exo2 h5 med page-head'>Create New User {org.id !== currentOrg.id ? `for ${org.brandname}` : '' }</h1>
            <UserForm currentUser={currentUser} onSubmit={onSubmit} disabled={loading} onCancel={handleCancel} />
          </Grid>
          <HelpSection xs={12} sm={12} md={3} lg={4} />
        </Grid>
      </Screen>
    </div>
  )
}
