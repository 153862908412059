import React, { useEffect } from 'react';
import { OvalLoading } from "../../components";
import { useDispatch } from "react-redux";
import { userActions} from "../../store";
import "./style.scss";

export default (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActions.logout());
  }, [])
  return (
    <div className='col w-100 h-100'>
      <OvalLoading message="Logging out..." />
    </div>
  )
}

