import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { orgActions, isOrgCreateSuccess, isLoading, getCurrentUser, getRolesIdsList, getRoles, getDesignationList, commonAction } from "../../store";
import { AppNavBar, Screen, HelpSection, ConfirmationBox, useNavigateBack, PaginationTab } from "../../components";
import { UserForm, UserFormFields } from "../Form";
import { OrgForm } from "./Components/Form";
import { AssignManagementTable } from "./Components/Management";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";

const TabItems = [
  { label: '1', key: 'org' },
  { label: '2', key: 'organiser' },
  { label: '3', key: 'ceo' },
]


export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateBack = useNavigateBack()
  const _formData = useRef({})
  const mngtUsers = useRef({})
  const loading = useSelector(isLoading);
  const user = useSelector(getCurrentUser);
  const [state, setState] = useState({ showConfirm: false, showCreateOrganiser: false, tab: 'org' })
  const isCreated = useSelector(isOrgCreateSuccess);
  const Roles = useSelector(getRolesIdsList)
  const RolesConstant = getRoles()
  const designations = useSelector(getDesignationList)
  useEffect(() => {
    dispatch(orgActions.fetchCountryList());
    dispatch(orgActions.fetchIndustryList());
  }, [])
  useEffect(() => {
    if (isCreated) {
      dispatch(orgActions.createOrUpdateOrgStatus({ create: false, updated: false }));
      setState((_) => ({ ..._, showConfirm: true }));
    }
  }, [isCreated])
  const handleOrgSubmit = (formData, json) => {
    if (formData && json) {
      _formData.current.org = { formData, json };
    }
    setState((_) => ({ ..._, tab: 'organiser' }));
  }
  const onOk = () => {
    setState((_) => ({ ..._, showConfirm: false }));
    navigate('/')
  }
  const handleOrganiserSave = (organiser) => {
    if (organiser) {
      _formData.current.organiser = organiser;
      let designation = designations.find(({ id }) => id === organiser.designation);
      mngtUsers.current = designation ? { [designation.short]: organiser } : null
    }
    setState((_) => ({ ..._, tab: 'ceo' }));
  }
  const handleSaveManagement = (seniorUsers) => {
    const { organiser } = _formData.current;
    const org = _formData.current.org.formData;
    let isDuplicateDesg = seniorUsers.find((u) => {
      return u.designation === organiser.designation && u.email !== organiser.email;
    })
    if (isDuplicateDesg) {
      dispatch(commonAction.setAlert({ success: false, title: "Duplicate User for " + isDuplicateDesg.designation }))
      return
    }
    seniorUsers = (seniorUsers || []).filter((u) => {
      return u.email !== organiser.email
    })
    UserFormFields.user.forEach((row) => {
      row.forEach((field) => {
        if (field.key) {
          org.append(field.key, organiser[field.name])
        }
      })
    });
    dispatch(orgActions.createNewOrg({ org, seniorUsers }));
  }
  const showNavBar = props.hideNavBar === true ? false : true
  const role = (Roles || []).find((_) => _.label === RolesConstant.Organiser);
  return (
    <div className='col w-100 h-100 o-hide'>
      {
        Boolean(user) &&
        <>
          {
            showNavBar && <AppNavBar items={[]} user={user} />
          }
          <Screen className='org-add col f-rest o-hide'>
            <Grid container spacing={0} classes={{ root: 'col h-100  o-hide' }}>
              <Grid item xs={12} sm={12} md={11} lg={10} classes={{ root: 'col h-100  o-hide' }} flexDirection="column">
                <PaginationTab items={TabItems} activeKey={state.tab} />
                <div className={`tab-container col f-rest oy-auto tab-${state.tab}`}>
                  {
                    state.tab === 'org' &&
                    <React.Fragment>
                      <h1 className='exo2 h5 med page-head'>Create new Organisation</h1>
                      <OrgForm
                        onSubmit={handleOrgSubmit}
                        onReset={navigateBack}
                        disabled={loading}
                        btnSubmit='Next'
                        btnReset='Back'
                        org={_formData.current.org ? _formData.current.org.json : null} />
                    </React.Fragment>
                  }
                  {
                    state.tab === 'organiser' &&
                    <React.Fragment>
                      <h1 className='exo2 h5 med page-head'>Create Organiser {_formData.current && _formData.current.json && _formData.current.json.brandname ? `for ${_formData.current.json.brandname}` : ''}</h1>
                      <UserForm
                        btnSubmit='Next'
                        btnReset='Back'
                        hiddenFields={['dept_lead']}
                        nonEditable={['role_id', 'supervisor']}
                        identicalFields={{ "email": "supervisor" }}
                        user={_formData.current.organiser || { role_id: role && role.id }}
                        currentUser={{ role: { name: RolesConstant.Administrator } }}
                        onSubmit={handleOrganiserSave}
                        onCancel={() => setState((_) => ({ ..._, tab: 'org' }))}
                        disabled={loading} />
                    </React.Fragment>
                  }
                  {
                    state.tab === 'ceo' &&
                    <React.Fragment>
                      <h1 className='exo2 h5 med page-head'>Senior Management</h1>
                      <AssignManagementTable
                        users={mngtUsers.current}
                        onCancel={() => handleOrgSubmit()}
                        onSubmit={handleSaveManagement}
                      />
                    </React.Fragment>
                  }
                </div>
              </Grid>
              <HelpSection xs={12} sm={12} md={1} lg={2} />
            </Grid>
          </Screen>
          {
            state.showConfirm &&
            <ConfirmationBox
              row={true}
              className='org-add-success'
              btnOK={{ text: 'Okay', onClick: onOk }}
              subtitleClassName=''
              title='Submission Successful'
              subtitle={[
                `Your Organisation and Organiser details have been submitted successfully.`
              ]}
            />
          }
        </>
      }
    </div>
  )
}
