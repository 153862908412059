import React, { useEffect, useState, useRef } from 'react';
import { FusionLogo, LinkButton, Button, Input, ConfirmationBox, ToastAlert } from "../../components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userActions, getPasswordChangeStatus, isLoading, getAlert, commonAction } from "../../store";
import "./style.scss";

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notification = useSelector(getAlert);
  const loading = useSelector(isLoading)
  const status = useSelector(getPasswordChangeStatus);
  const [state, setState] = useState({ email: '', error: false });
  const _timerRef = useRef();
  useEffect(() => {
    if (status) {
      dispatch(userActions.sendPasswordLinkSuccess(false))
      setState((_) => ({ ..._, email: '', error: '' }))
    }
  }, [status])
  useEffect(() => {
    if (notification) {
      let autoClose = notification.autoClose === undefined ? true : notification.autoClose;
      if (autoClose) {
        _timerRef.current = setTimeout(closeConfirmation, 3000);
      }
    }
  }, [notification])
  const closeConfirmation = () => {
    if (_timerRef.current) {
      clearTimeout(_timerRef.current);
    }
    dispatch(commonAction.setAlert(null));
  }
  const onValueChange = (e) => {
    setState((_) => ({ ..._, email: e.target.value, error: '' }))
  }
  const onSubmit = (e) => {
    e.preventDefault();
    if (!Boolean(state.email.trim())) {
      return setState((_) => ({ ..._, error: 'req' }))
    }
    dispatch(userActions.sendPasswordLink({ email: state.email }))
  }
  return (
    <div className='login-main row w-100 h-100 h-ctr v-start forget-pass'>
      <div className='col login-box '>
        <div className='col w-100 v-ctr '>
          <FusionLogo className='aud-logo' />
        </div>
        <form className='login-form' autoComplete='off' onSubmit={onSubmit} noValidate>
          <div className='h3 c-primary exo2 head'>Forgot your Password?</div>
          <div className='f18 subtitle'>Type your Email to receive Password Reset link</div>
          <Input
            type="email"
            className='f16'
            name='email'
            placeholder="email@domain.com"
            value={state.email}
            error={state.error}
            onChange={onValueChange} />
          <div className='row h-btn h-ctr '>
            <LinkButton label="Back to Login" onClick={() => navigate("/login")} />
            <Button color='#15C' className='bold f16' type="submit"
              text="Send Link"
              disableElevation />
          </div>
        </form>
      </div>
      {
        Boolean(notification) &&
        <React.Fragment>
          {
            notification.severity === 'success' ?
              <ConfirmationBox
                {...notification}
                btnOK={{ onClick: closeConfirmation }}
                className='custom-width'
              />
              :
              <ToastAlert title={notification.title} onClose={closeConfirmation} />
          }
        </React.Fragment>
      }
    </div>
  )
}

