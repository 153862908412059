import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import {
  Input, Screen, HelpSection, PrimaryButton, RightArrowIcon, AuditorModuleCheck, ArcModuleCheck, ConsoleModuleCheck,
  ConfirmationBox, CheckmarkIcon, useNavigateBack, isNavHistoryExist
} from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { isLoading, orgActions, userActions, isOrgUserExist, isUserInvited, getCurrentUserOrg, getOrgDetailsById } from "../../store";
import "./style.scss";

const TabBtn = (props) => {
  const { tabNum, activeTab, ...rest } = props
  return (
    <button className={`tab-btn ${activeTab === tabNum ? 'active' : ''}`} {...rest}>
      <span>{tabNum}</span>
    </button>
  )
}
const TabBtnDivider = (props) => {
  return (
    <div className='row f-rest'>
      <div className='col w-100 tab-divider'></div>
    </div>
  )
}
const initialState = {
  activeTab: 1, email: "", emailError: '', modules: [], message: ''
}

export default (props) => {
  const navigate = useNavigate();
  const navigateBack = useNavigateBack()
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const loading = useSelector(isLoading);
  const org = useSelector(orgId ? getOrgDetailsById.bind(null, orgId) : getCurrentUserOrg)
  const isUserExist = useSelector(isOrgUserExist);
  const isInvited = useSelector(isUserInvited);
  const [state, setState] = useState(JSON.parse(JSON.stringify(initialState)));
  useEffect(() => {
    if (isUserExist) {
      if (!isUserExist.isMappingExist) {
        setState((_) => ({ ..._, activeTab: 2, modules: [], email: isUserExist.email || _.email }))
      }
    }
  }, [isUserExist])

  const handleInviteOrgBtn = () => {
    if (state.email === '') {
      setState((_) => ({ ..._, emailError: 'required' }));
      return;
    }
    if (state.activeTab === 1) {
      //check if the Email Exist
      dispatch(orgActions.isOrgUserExist({ email: state.email, currentUserOrg: org.id }))
    } else if (state.activeTab === 2) {
      setState((_) => ({ ..._, showConfirmation: true }))
    } else {
      setState((_) => ({ ..._, activeTab: 3 }))
    }
  }
  const handleTabSelection = (tab) => {
    if (tab < state.activeTab) {
      setState((_) => ({ ..._, activeTab: tab, modules: tab === 1 ? [] : _.modules }))
    }
  }
  const handleModuleSelection = (mod) => {
    if (state.activeTab === 2) {
      const modules = [...state.modules];
      let index = modules.indexOf(mod);
      if (index === -1) {
        modules.push(mod);
      } else {
        modules.splice(index, 1);
      }
      setState((_) => ({ ..._, modules: modules }))
    }
  }
  const handleConfirm = () => {
    setState((_) => ({ ..._, showConfirmation: false }))
    let body = { email: state.email, modules: state.modules };
    if (orgId) {
      body.orgId = orgId
    }
    dispatch(userActions.inviteUser(body));
  }
  const getButtonProps = () => {
    let p = { loading: loading, className: 'btn-invite' },
      isOrg = isUserExist && isUserExist.isOrgExist;
    if (state.activeTab === 1) {
      p.onClick = handleInviteOrgBtn;
      p.text = isOrg ? 'Request approval' : 'Invite Org';
      p.textLoading = 'Checking...';
      p.className += isOrg ? ' req' : ''
    } else if (state.activeTab === 2) {
      p.onClick = isOrg ? handleConfirm : handleInviteOrgBtn;
      p.text = 'Confirm'
      p.textLoading = isOrg ? 'Requesting...' : 'Inviting...'
    }
    return p;
  }
  const handleOk = () => {
    if (isNavHistoryExist()) {
      navigate(-1)
    } else {
      navigate('')
    }
  }
  return (
    <Screen className='org-invite col w-100 h-100 o-hide'>
      <Grid container spacing={0} classes={{ root: "h-100 o-hide" }}>
        <Grid item xs={12} sm={12} md={9} lg={9} classes={{ root: "invite-container h-100 hsc oy-auto" }}>
          <h1 className='exo2 h5 med page-head'>Invite External Organisation {orgId ? `for ${org.brandname}` : ''}</h1>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={6} lg={6} classes={{ root: "invite-card" }}>
              <div className='row w-100 tabs'>
                <TabBtn
                  tabNum={1} activeTab={state.activeTab}
                  onClick={() => handleTabSelection(1)} />
                <TabBtnDivider />
                <TabBtn
                  tabNum={2} activeTab={state.activeTab}
                  onClick={() => handleTabSelection(2)} />
                {/* <TabBtnDivider />
                <TabBtn tabNum={3} activeTab={state.activeTab} /> */}
              </div>
              <div className='col tab-container'>
                <div className='h5 exo2 sum-title bold'>
                  {state.activeTab === 3 ? 'Summary' : ''}
                </div>
                <Input
                  className="h7"
                  labelClassName="h11"
                  error={state.emailError}
                  value={state.email}
                  placeholder='email@domain.com'
                  disabled={state.activeTab !== 1}
                  onChange={(e) => setState((_) => ({ ..._, email: e.target.value, emailError: '' }))}
                  name="email" label="Primary SPoC Email (belonging to the external Organisation)"
                />
                {
                  state.activeTab == 2 && !isInvited &&
                  <div className='h6 med check-msg'>
                    {
                      isUserExist && isUserExist.isOrgExist ?
                        'Organisation exists. Send approval request for Mapping.' :
                        'This Organisation DOES NOT exist. Send invitation?'
                    }
                  </div>
                }
                {
                  Boolean(isUserExist && isUserExist.isMappingExist) &&
                  <div className='h6 med check-msg'>Organisation Mapping Already Exist</div>
                }
                {/*
                  Boolean(state.activeTab > 1 && isUserExist && !isUserExist.isMappingExist) &&
                  <div className='mod-list-cont'>
                    <h6 className='h8 title'>Allow access to:</h6>
                    <div className='mod-list'>
                      <AuditorModuleCheck
                        onClick={handleModuleSelection}
                        checked={state.modules.indexOf('auditor') > -1} />
                      <ArcModuleCheck
                        onClick={handleModuleSelection}
                        checked={state.modules.indexOf('arc') > -1} />
                      <ConsoleModuleCheck checked className='def-mod' />
                    </div>
                  </div>
              */}
                {
                  isInvited &&
                  <>
                    {
                      isUserExist && isUserExist.isOrgExist ?
                        <div className='h6 blink_me success'>Request for Mapping this Organisation is successfully submitted.</div>
                        :
                        <>
                          <div className='h6 blink_me success'>Request submitted!</div>
                          <div className='h10 success-note'>Invite will be sent to <span className='bold'>{state.email}</span>.</div>
                        </>
                    }
                  </>
                }
                {
                  Boolean(!isInvited && (!isUserExist || (isUserExist && !isUserExist.isMappingExist))) &&
                  <PrimaryButton
                    endIcon={state.activeTab != 3 && <RightArrowIcon />}
                    {...getButtonProps()} />
                }
                {
                  Boolean(isInvited || (isUserExist && isUserExist.isMappingExist)) &&
                  <PrimaryButton
                    text='Okay' className='btn-ok' onClick={handleOk} />
                }
              </div>
            </Grid>
          </Grid>
        </Grid>
        <HelpSection xs={12} sm={12} md={3} lg={3} />
      </Grid>
      {
        state.showConfirmation &&
        <ConfirmationBox
          row={true}
          className='invite-confirm'
          btnOK={{
            text: 'Yes, Send',
            className: 'h11',
            onClick: handleConfirm,
            endIcon: <CheckmarkIcon className='h12' />
          }}
          titleClassName='h6'
          subtitleClassName='h10'
          btnCancel={{
            type: 'link',
            className: 'h11',
            onClick: () => setState((_) => ({ ..._, showConfirmation: false }))
          }}
          title='Invite External Organisation?'
          subtitle={[
            'This will send an email for creation of a Fusion Enterprise Account.',
            'The email address you provided will become the Organiser for this organisation',
            { html: `Recipient Email: <span class='cFF3333'>${state.email}</span>` }
          ]}
        />
      }
    </Screen>
  )
}
