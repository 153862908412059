import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, } from "react-redux";
import { useParams } from "react-router-dom";
import { Input, PrimaryButton } from "../../components";
import { orgActions, getCurrentUserOrg, getOrgSSO } from "../../store";
import { Grid, CircularProgress } from "@mui/material";
import "./style.scss";

const Fields = [
  { "name": "client_id", "type": "text", "label": "Client ID" },
  { "name": "tenant_id", "type": "text", "label": "Tenant ID " },
  { "name": "client_secret", "type": "text", "label": "Client Secret Key" },
]

function getFormValue(sso) {
  const _sso = { client_id: '', tenant_id: '', client_secret: "" };
  _sso.client_id = (sso && sso.client_id) || '';
  _sso.tenant_id = (sso && sso.tenant_id) || '';
  _sso.client_secret = (sso && sso.client_secret) || '';
  return _sso;
}

export default (props) => {
  const { isInternal } = props
  const dispatch = useDispatch()
  const { orgId } = useParams();
  const org = useSelector(getCurrentUserOrg);
  const [state, setState] = useState({ editable: false, isEdited: false });
  const sso = useSelector(getOrgSSO.bind(null, (isInternal ? orgId : org.id)));
  const [formData, setFormData] = useState(getFormValue(sso));
  useEffect(() => {
    let _orgId = isInternal ? orgId : org.id;
    dispatch(orgActions.fetchOrgSSO({ orgId: _orgId }));
  }, [])
  useEffect(() => {
    if (sso) {
      setFormData(getFormValue(sso))
    }
  }, [sso])
  const handleValueChange = (e) => {
    const { name, value } = e.target;
    const _data = { ...formData };
    _data[name] = value;
    setFormData({ ..._data });
    if (!state.isEdited) {
      setState((_) => ({ ..._, isEdited: true }))
    }
  }
  const handleClick = () => {
    setState((_) => ({ ..._, editable: !_.editable, isEdited: false }))
  }
  const getFormFieldValue = (value) => {
    if (state.editable) {
      return value
    }
    if (value === '') {
      return " ";
    }
    let formated = value.split("-");
    if (formated[1]) {
      formated[1] = "****"
    }
    if (formated[3]) {
      formated[3] = "****"
    }
    return formated.join("-");
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!state.isEdited) {
      return handleClick();
    }
    let _orgId = isInternal ? orgId : org.id;
    dispatch(orgActions.updateOrgSSO({ adfs: formData, orgId: _orgId }))
    handleClick();
  }
  return (
    <div className={`col  w-100 h-100 o-hide sso ${isInternal ? 'internal' : ''}`}>
      {
        Boolean(sso) ?
          <Grid container>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <div className='exo2 h6 title'>ADFS Configuration</div>
              <form className='sso-form' onSubmit={handleSubmit}>
                {
                  Fields.map((_) => {
                    return (
                      <Input
                        {..._}
                        key={_.name}
                        className='h7'
                        value={getFormFieldValue(formData[_.name])}
                        onChange={handleValueChange}
                        editable={state.editable} />
                    )
                  })
                }
                {
                  state.editable &&
                  <PrimaryButton type="submit" text='Save' />
                }
              </form>
              {
                !state.editable &&
                <PrimaryButton type="button" text="Edit" onClick={handleClick} className="edit-btn" />
              }
            </Grid>
          </Grid>
          :
          <div className='col f-rest v-ctr h-ctr'>
            <CircularProgress />
          </div>
      }

    </div>
  )
}

