import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { UserForm } from "../../Form";
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { getRoles, getRolesIdsList, commonAction, getDesignationList } from "../../../store";
import { Table, PrimaryButton, Button, RightArrowIcon } from "../../../components";

const Roles = getRoles()

const Columns = [
  { label: "Designation", width: '30%', align: 'left', type: 'html', attr: 'designation_text', dataCellClassName: 'c00085' },
  { label: "Name", width: '30%', align: 'left', attr: 'name', dataCellClassName: 'c00085' },
  { label: "Email", width: '25%', align: 'left', attr: 'email', dataCellClassName: 'c00085' },
  { label: "Action", width: '15%', type: 'actions', actions: [{ label: 'Nominate', action: 'nominate' }] },
]
const RoleDesignationMap = [
  { title: 'CEO', role: Roles.Assessor, department_name: 'Technology', required: true },
  { title: 'CTO', role: Roles.Assessor, department_name: 'Technology', attribute: '' },
  { title: 'CFO', role: Roles.Assessor, department_name: 'Finance', attribute: '' },
  { title: 'COO', role: Roles.Assessor, department_name: 'Operations', attribute: '' },
  { title: 'CISO', role: Roles.Organiser, department_name: 'InfoSec', attribute: '' },
  { title: 'CHRO', role: Roles.Assessor, department_name: 'HR', attribute: '' },
  { title: 'CPO', role: Roles.Assessor, department_name: 'HR', attribute: '' },
  { title: 'DPO', role: Roles.Organiser, department_name: 'InfoSec', attribute: '' },
];

const NominateForm = (props) => {
  const { user } = props;
  const isCEO = String(user.title).toLowerCase() === 'ceo';
  const identicalFields = isCEO ? { "email": "supervisor" } : null;
  const nonEditable = ['role_id', 'department_name', 'designation'].concat(isCEO ? ['supervisor'] : [])
  return (
    <Dialog className='dialog' classes={{ paper: `senior-dialog` }} open>
      <DialogTitle classes={{ root: 'title' }}>
        <div className='h8 bold c-primary exo2'>Nominate {user.title}</div>
      </DialogTitle>
      <DialogContent>
        <UserForm
          user={user}
          roleLevelLimit={Roles.Organiser}
          hiddenFields={['dept_lead']}
          nonEditable={nonEditable}
          btnSubmit='Save'
          identicalFields={identicalFields}
          onSubmit={props.onSubmit}
          onCancel={props.onCancel}
        />
      </DialogContent>
    </Dialog>
  )
}


export const AssignManagementTable = (props) => {
  const dispatch = useDispatch();
  const RoleIds = useSelector(getRolesIdsList);
  const designations = useSelector(getDesignationList);
  const [state, setState] = React.useState({ showNomineeDialog: false, initialUser: '', users: (props.users || {}) });
  const getRows = () => {
    let rows = designations.map(({ label, id, short }) => {
      let designation = { designation_text: label, designation: id };
      const user = state.users[short];
      const row = RoleDesignationMap.find((_) => (_.title === short));
      if (row) {
        designation = { ...designation, ...row }
        if (user) {
          designation.name = `${user.firstname} ${user.lastname}`;
          designation.email = user.email;
        }
        if (row.required) {
          designation.designation_text += ' <span class="req"></span>'
        }
      }
      return designation;
    });
    return rows;
  }
  const handleNominate = (action, row) => {
    let initialUser = null;
    if (row) {
      const role = RoleIds.find((_) => _.idStr === row.role);
      initialUser = {
        title: row.title,
        designation: row.designation,
        role_id: role && role.id,
        department_name: row.department_name,
        ...(state.users[row.title] || {})
      };
    }
    setState((_) => ({ ..._, showNomineeDialog: Boolean(initialUser), initialUser: initialUser }))
  }
  const handleSaveUser = (formData) => {
    const { initialUser } = state;
    const users = JSON.parse(JSON.stringify({ ...state.users }));
    users[initialUser.title] = formData;
    setState((_) => ({ ..._, initialUser: null, showNomineeDialog: false, users }))
  }
  const handleSubmit = () => {
    let message = null;
    for (let i = 0; i < RoleDesignationMap.length; i++) {
      const { required, title } = RoleDesignationMap[i];
      if (required && !state.users[title]) {
        message = `${title} details are required`;
        break;
      }
    };
    if (message) {
      return dispatch(commonAction.setAlert({ success: false, title: message }))
    }
    props.onSubmit && props.onSubmit(Object.values(state.users));
  }
  return (
    <React.Fragment>
      <div className='col rest'>
        <Table
          columns={Columns}
          className='w-100 h-100 tbl-mng'
          rows={getRows()}
          onAction={handleNominate}
        />
      </div>
      <div className='row v-ctr form-actions'>
        <PrimaryButton endIcon={<RightArrowIcon />} text='Submit' onClick={handleSubmit} />
        <Button text='Back' className='h8 med reset-btn' onClick={props.onCancel} />
      </div>
      {
        state.showNomineeDialog &&
        <NominateForm
          user={state.initialUser}
          onSubmit={handleSaveUser}
          onCancel={() => handleNominate(null, null)}
        />
      }
    </React.Fragment>
  )
}