import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Grid, CircularProgress } from "@mui/material";
import { Screen, HelpSection, useNavigateBack } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { userActions, getFetchedUser, getRoles } from "../../store";
import { UserForm } from "../Form";
import "./style.scss";

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateBack = useNavigateBack();
  const { userId, orgId } = useParams();
  const RolesConstant = getRoles()
  const user = useSelector(getFetchedUser);
  useEffect(() => {
    if (!userId) {
      return navigate("/page-not-found")
    }
    dispatch(userActions.fetchUser({ userId }))
  }, [])
  useEffect(() => {
    if (user === "NotFound") {
      navigate('/page-not-found')
    }
  }, [user])
  const handleCancel = () => {
    dispatch(userActions.createUserSuccess(false))
    navigateBack()
  }
  const onSubmit = () => {
    if (orgId) {
      navigate(`/O/${orgId}/user/${userId}/edit`)
    } else {
      navigate('/U/' + userId + "/edit")
    }
  }
  return (
    <div className='col w-100 h-100 o-hide'>
      <Screen className='user-add col f-rest o-hide'>
        <Grid container spacing={0} classes={{root: 'o-hide'}}>
          <Grid item xs={12} sm={12} md={9} lg={8} classes={{ root: 'col h-100  o-hide' }} direction="column">
            {
              (user && user !== 'NotFound') ?
                <>
                  <h1 className='exo2 h5 med page-head'>User Information</h1>
                  <UserForm
                    readOnly
                    btnSubmit='Edit'
                    btnReset='Back'
                    user={user}
                    onSubmit={onSubmit}
                    onCancel={handleCancel}
                    currentUser={{ role: { name: RolesConstant.Administrator } }} />
                </>
                :
                <CircularProgress />
            }
          </Grid>
          <HelpSection xs={12} sm={12} md={3} lg={4} />
        </Grid>
      </Screen>
    </div>
  )
}
