import React, { useEffect, useRef } from 'react';
import "./style.scss";
import { LinkButton } from "../Button";
import { AutoCompleteSelect } from "../Input";
import { OvalLoading, AppErrorBoundry } from "../Screen";

const GroupChild = (props) => {
  const { isHead, children, content } = props;
  return (
    <div className='row f-rest child-row font-parent'>
      {
        children.map((child, i) => {
          const hasChildren = Boolean(child.children)
          return (
            <div className={`col f-rest font-parent ${isHead ? 'h7' : 'h10'}`} key={i}>
              {isHead ? child.label : (content && !hasChildren ? content[child.attr] : '')}
              {
                hasChildren &&
                <GroupChild isHead={isHead} children={child.children} content={content && content[child.attr]} />
              }
            </div>
          )
        })
      }
    </div>
  )
}

const Cell = (props) => {
  const { isHead, label, children, content, type, dataCellClassName, actions, headCellClassName, isClickable, cellFont } = props;
  return (
    <div
      onClick={isClickable ? props.onCell : undefined}
      className={`col cell-content ${(isHead ? `exo2 ${headCellClassName || ''}` : dataCellClassName) || ''} ${!isHead && isClickable ? 'click' : ""}`}>
      {Boolean(isHead) && <div className='font-parent'>{label}</div>}
      {
        (type === 'actions' && !Boolean(isHead)) ?
          <>
            {
              Array.isArray(actions) ?
                (actions || []).map((_) => {
                  return (
                    <LinkButton
                      className={`h10 proper-case ${_.action}`}
                      label={_.label} key={_.action}
                      onClick={() => props.onAction(_)} />
                  )
                })
                :
                <span className='w-100 h10 proper-case'>{actions}</span>
            }
          </>
          : Boolean(children) ?
            <GroupChild
              children={children}
              attr={props.attr}
              isHead={isHead}
              content={content} />
            : !Boolean(isHead) ?
              <>
                {
                  props.input ?
                    <>
                      {
                        props.type === 'select' ?
                          <AutoCompleteSelect
                            disablePortal={false}
                            className='h10'
                            value={content}
                            options={props.list}
                            onChange={props.onInputChange} />
                          : null
                      }
                    </>
                    : (type === 'html') ?
                      <span className={cellFont || 'h10'} dangerouslySetInnerHTML={{ __html: content }}></span>
                      :
                      <span className={cellFont || 'h10'}>{content}</span>
                }
              </>
              : null
      }
    </div>
  )
}

export const Table = (props) => {
  const { columns, rows, className, emptyMessage } = props;
  const headerRef = useRef(null)
  const bodyRef = useRef(null)
  const handleScrollBar = () => {
    if (Array.isArray(rows) && rows.length > 0) {
      if (bodyRef.current.scrollHeight > bodyRef.current.clientHeight) {
        headerRef.current.classList.add('pad')
      } else {
        headerRef.current.classList.remove('pad')
      }
    }
  }
  useEffect(() => {
    handleScrollBar()
  }, [rows])
  return (
    <div className={`col user-table ${className || ''}`}>
      <div className='col thead' ref={headerRef}>
        <div className='row v-end'>
          {
            columns.map((col, i) => {
              return (
                <div key={i} width={col.width}
                  className={`col h7 head-color head-cell reg ${className || ''}`}
                  style={{ textAlign: col.align || 'center', width: col.width }}>
                  <Cell isHead {...col} />
                </div>
              )
            })
          }
        </div>
      </div>
      {
        Array.isArray(rows) ?
          <AppErrorBoundry>
            {
              rows.length > 0 ?
                <div className='tbody col no-scroll-bar' ref={bodyRef}>
                  {
                    rows.map((row, i) => {
                      return (
                        <div key={i} className="row">
                          {
                            columns.map((col, j) => {
                              return (
                                <div
                                  key={j}
                                  className={`col color data-cell ${col.className || ''}`}
                                  style={{ textAlign: col.align || 'center', width: col.width }}>
                                  {
                                    (typeof col.render === "function") ?
                                      col.render(row)
                                      :
                                      <Cell
                                        isHead={false}
                                        {...col}
                                        content={row[col.attr]}
                                        actions={row.actions || col.actions}
                                        onCell={() => props.onCell && props.onCell(row, col)}
                                        onAction={(action) => props.onAction && props.onAction(action, row)}
                                        onInputChange={(e) => col.onFieldInput && col.onFieldInput(e, row)} />
                                  }
                                </div>
                              )
                            })
                          }
                        </div>
                      )
                    })
                  }
                </div>
                :
                <div className='tbody h13 col v-ctr h-ctr f-rest no-data'>
                  {emptyMessage || 'No Data'}
                </div>
            }
          </AppErrorBoundry>
          :
          <OvalLoading isSmall />
      }
    </div>
  )
}