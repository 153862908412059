import { createSlice } from "@reduxjs/toolkit";
import { put } from "redux-saga/effects";
import SagaRegistryHelper from "./SagaRegistry";
import ReducerRegistryHelper from "./ReducerRegistry";
import moment from "moment";
import { encode, decode } from "base-64";
const DATE_FORMAT = 'ddd, DD-MMM-YY';

const initialState = { notification: null, loading: false, fullLoading: false }

const commonSlice = createSlice({
  name: "common",
  initialState: initialState,
  reducers: {
    setAlert: (state, actions) => {
      state.notification = actions.payload
    },
    setLoading: (state, actions) => {
      state.loading = actions.payload
    },
    setFullLoading: (state, actions) => {
      state.fullLoading = actions.payload
    }
  }
})
const actions = commonSlice.actions;

export function* setAlertAndLoading(isLoading = null, alert = null, fullLoading = null) {
  if (alert !== null) {
    alert.severity = alert.success === false ? 'error' : 'success';
    yield put(actions.setAlert(alert));
  }
  if (isLoading !== null) {
    yield put(actions.setLoading(isLoading));
  }
  if (fullLoading !== null) {
    yield put(actions.setLoading(fullLoading));
  }
}

function* onSagaErrorCatch(error) {
  error = typeof error == "string" ? JSON.parse(error) : error;
  if (error.data) {
    const alert = { success: false, title: (error.data && error.data.message) || 'Request Failed' }
    yield setAlertAndLoading(false, alert, false)
  }
}
export const getDateInFormat = (iDate) => {
  return getMomentDate(iDate).format(DATE_FORMAT)
}
export const getMomentDate = (iDate) => {
  return moment(iDate)
}
export const sortByMapping = (itemA, itemB) => {
  const org_mappingA = itemA.org_mapping.updated_at;
  const org_mappingB = itemB.org_mapping.updated_at;
  if (moment(org_mappingA).isAfter(org_mappingB)) {
    return -1
  } else if (moment(org_mappingA).isBefore(org_mappingB)) {
    return 1
  }
  return 0
}
export const sortByDate = (prop, itemA, itemB) => {
  if (moment(itemA[prop]).isAfter(itemB[prop])) {
    return -1
  } else if (moment(itemA[prop]).isBefore(itemB[prop])) {
    return 1
  }
  return 0
}
const getErrorInFormat = (e) => {
  if (e && e.data && e.data.message) {
    return e
  } else {
    let messages = [];
    if (e && e.data && e.data.errors) {
      for (const field in e.data.errors) {
        if (Object.hasOwnProperty.call(e.data.errors, field)) {
          if (field === 'base') {
            messages = messages.concat(e.data.errors[field])
          } else {
            messages = messages.concat(e.data.errors[field].map((_) => `${field} ${_}`))
          }
        }
      }
    } else {
      messages = 'Request Failed'
    }
    return { data: { message: messages } }
  }
}
export function* requestHandler(callback) {
  try {
    yield callback()
  } catch (error) {
    console.log("error", error);
    yield onSagaErrorCatch(getErrorInFormat(error));
  }
}
export const SagaRegistry = new SagaRegistryHelper();
export const ReducerRegistry = new ReducerRegistryHelper();
export const commonAction = actions;
ReducerRegistry.register('common', commonSlice.reducer);

const getState = (state) => state.common;
export const isLoading = (state) => {
  const _state = getState(state);
  return _state.loading
}
export const getFullLoading = (state) => {
  const _state = getState(state);
  return _state.fullLoading
}
export const getAlert = (state) => {
  const _state = getState(state);
  return _state.notification
}
export const encyptDataObject = (iData) => {
  let parsed = JSON.stringify(iData);
  parsed = encode(parsed);
  return parsed;
}
export const decryptDataObject = (iDataString) => {
  let decoded = decode(iDataString);
  decoded = JSON.parse(decoded);
  return decoded;
}
