import React, { useState, useEffect } from 'react';
import { Grid } from "@mui/material";
import { Input, AutoCompleteSelect, Textarea, PrimaryButton, FileInput, RightArrowIcon, Button } from "../../../components";
import { getIndustryList, getCountryList } from "../../../store";
import { useSelector } from "react-redux";
import FormFields from "./formFields.json";
import "../style.scss";

export const OrgForm = (props) => {
  const { org, btnSubmit, btnReset, onSubmit } = props;
  const hideReset = props.hideReset === true ? true : false
  const industryList = useSelector(getIndustryList);
  const countryList = useSelector(getCountryList);
  const [state, setState] = useState({ formData: null, error: {} });
  useEffect(() => {
    setState((_) => ({ ..._, formData: getFormData(org) }))
  }, []);
  const onAddSubmit = (e) => {
    e.preventDefault();
    const error = {}, json = state.formData;
    const form = new FormData(e.target);
    const result = new FormData();
    FormFields.org.map((row) => {
      row.map((field) => {
        let value = json[field.name];
        if (field.required && !value) {
          error[field.name] = 'required'
        } else {
          if (field.type === 'file') {
            let files = value;
            if (files && !files.isNotFile) {
              result.append(field.key, files);
            }
          } else {
            if (field.type === 'select') {
              json[field.name] = Boolean(value && (typeof value === "object")) ? value.id : value;
            }
            result.append(field.key, form.get(field.name));
          }
        }
      });
    });
    if (Object.keys(error).length > 0) {
      setState((_) => ({ ..._, error: error }))
      return
    }
    onSubmit && onSubmit(result, json);
  }
  const onValueChange = (e) => {
    const { name, value, type, files } = e.target, formData = { ...state.formData }, error = { ...state.error };
    formData[name] = type === 'file' ? files[0] : value;
    error[name] = false
    setState((_) => {
      return { ..._, formData: { ...formData }, error: { ...error } }
    })
  }
  const getFormData = (org) => {
    const data = {};
    FormFields.org.map((row) => {
      row.map((field) => {
        let obj = org ? (field.isfacility ? (org.facility || org) : org) : {};
        try {
          let value = obj[field.name]
          if (field.type === 'select') {
            let options = getOptions(field.name);
            data[field.name] = value ? options.find((_) => _.id === value) : null;
          } else if (field.type === 'file') {
            let fileName = obj[`${field.name}_file_name`]
            data[field.name] = fileName ? { isNotFile: true, name: fileName, url: obj[`${field.name}_url`] } : value;
            console.log("f", data[field.name], value);
          } else {
            data[field.name] = value || '';
          }
        } catch (error) {
          console.error("getFormData", obj, field.name);
        }
      })
    });
    return data;
  }
  const getOptions = (field) => {
    if (field === 'industry') {
      return industryList;
    } else if (field === 'country') {
      return countryList;
    }
    return []
  }
  return (
    state.formData === null ? null
      :
      <form className='form col oy-auto' onSubmit={onAddSubmit} id="org-form">
        {
          FormFields.org.map((row, index) => {
            return (
              <Grid container spacing={6} key={index} direction="row" justifyContent="space-between" >
                {
                  row.map(field => {
                    const { isfacility, ...rest } = field;
                    const _props = { error: state.error[field.name], disabled: props.disabled, labelClassName: 'h12', className: "h10", onChange: onValueChange, value: state.formData[field.name], ...rest };
                    return (
                      <Grid item xs={12} sm={12} md={field.width} lg={field.width} key={field.name}>
                        {
                          field.type === 'select' ?
                            <AutoCompleteSelect
                              options={getOptions(field.name)}
                              {..._props}
                            />
                            : field.type === 'file' ?
                              <FileInput {..._props}
                              />
                              :
                              field.type === 'textarea' ?
                                <Textarea {..._props}
                                />
                                :
                                <Input {..._props} type={field.type}
                                />
                        }
                      </Grid>
                    )
                  })
                }
              </Grid>
            )
          })
        }
        <div className='row v-ctr h-btn'>
          <div className='row'>
            <PrimaryButton disabled={props.disabled} type='submit' endIcon={<RightArrowIcon />} text={btnSubmit || 'Submit Details'} />
            {
              !hideReset &&
              <Button disabled={props.disabled} text={btnReset || 'Reset Form'} className='h8 med reset-btn' onClick={props.onReset} />
            }
          </div>
          {
            Boolean(props.deactivate) && org && !org.is_disabled &&
            <Button disabled={props.disabled} color='#FF3333' text="Deactivate Org" className='h8 med btn-deact' onClick={props.onDeactivate} />
          }
        </div>
      </form>
  )
}