import React from 'react';
import LogInOval from "../../assets/images/login-oval.png";
import SetPasswordOval from "../../assets/images/reset-pass-eval.png";
import AuditorLogoImg from "../../assets/images/auditor-logo.png";
import ConsoleLogoImg from "../../assets/images/console-logo.png";
import NeumetricLogoImg from "../../assets/images/neumetric-logo.png";
import ProfilePicImg from "../../assets/images/profile_pic.png";
import AuditorIconImg from "../../assets/images/auditor-icon.png";
import ArcIconImg from "../../assets/images/arc-icon.png";
import CopyRightImg from "../../assets/images/copy-write.png";
import Eval404Img from "../../assets/images/404-eval.png";
import LoadingOvalImg from "../../assets/images/Loading_Oval.png";
import LoadingGIFImg from "../../assets/images/Loading.gif";
import ShieldOvalImg from "../../assets/images/shield-oval.png";
import FusionImg from "../../assets/images/fusion-logo.png";
import DocumentorIconImg from "../../assets/images/documenter-icon.png";
import ControllerIconImg from "../../assets/images/controller-icon.png";
import EducatorIconImg from "../../assets/images/educator-icon.png";
import CentralIconImg from "../../assets/images/central-icon.png";
import InvestigatorIconImg from "../../assets/images/investigator-icon.png";
import ContinuerIconImg from "../../assets/images/continuer-icon.png";
import WarningIconImg from "../../assets/images/warning.png";


//logos
export const FusionLogo = (props) => {
  return (
    <img src={FusionImg} alt='Fusion Logo'  {...props} />
  )
}

export const AuditorLogo = (props) => {
  return (
    <img src={AuditorLogoImg} {...props} />
  )
}
export const ConsoleLogo = (props) => {
  return (
    <img src={ConsoleLogoImg} {...props} />
  )
}
export const NeumetricLogo = (props) => {
  return (
    <img src={NeumetricLogoImg} {...props} />
  )
}
//icons
export const AuditorIcon = (props) => {
  return (
    <img src={AuditorIconImg} {...props} />
  )
}
export const ArcIcon = (props) => {
  return (
    <img src={ArcIconImg} {...props} />
  )
}
export const EducatorIcon = (props) => {
  return (
    <img src={EducatorIconImg} {...props} />
  )
}
export const CentralIcon = (props) => {
  return (
    <img src={CentralIconImg} {...props} />
  )
}
export const DocumentorIcon = (props) => {
  return (
    <img src={DocumentorIconImg} {...props} />
  )
}
export const ControllerIcon = (props) => {
  return (
    <img src={ControllerIconImg} {...props} />
  )
}
export const InvestigatorIcon = (props) => {
  return (
    <img src={InvestigatorIconImg} {...props} />
  )
}
export const ContinuerIcon = (props) => {
  return (
    <img src={ContinuerIconImg} {...props} />
  )
}
export const WarningIcon = (props) => {
  return (
    <img src={WarningIconImg} {...props} />
  )
}
export const CopyRight = (props) => {
  return (
    <img src={CopyRightImg} {...props} />
  )
}
export const Eval404 = (props) => {
  return (
    <img src={Eval404Img} {...props} />
  )
}
export const LoadingOval = (props) => {
  return (
    <img src={LoadingOvalImg} {...props} />
  )
}
export const LoadingGIF = (props) => {
  return (
    <img src={LoadingGIFImg} {...props} />
  )
}
export const ShieldOval = (props) => {
  return (
    <img src={ShieldOvalImg} {...props} />
  )
}
export const ProfilePic = (props) => {
  return (
    <img src={ProfilePicImg} {...props} />
  )
}
export const LogInOvalImg = (props) => {
  return (
    <img src={LogInOval} {...props} />
  )
}
export const SetPasswordOvalImg = (props) => {
  return (
    <img src={SetPasswordOval} {...props} />
  )
}