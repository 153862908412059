import "./saga";
export * from "./slice";

const getAssessmentState = (state) => state.assessment;

export const getStatusList = (state) => {
  let _state = getAssessmentState(state);
  return _state.statusList;
}
export const getAssessment = (asmtId,  state) => {
  let _state = getAssessmentState(state);
  return _state.assessments[asmtId];
}
export const getUpdatedAsmt = (state) => {
  let _state = getAssessmentState(state);
  return _state.updatedAsmt;
}
