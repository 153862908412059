import React, { useRef, useState, useEffect } from 'react';
import { Autocomplete, FormControlLabel, Radio, Select } from '@mui/material';
import { ControlledMenu, MenuItem, SubMenu, useMenuState } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';

const DropdownContainer = (props) => {
  const { className, label, id, disabled, placeholder, search, error, required, ...rest } = props
  return (
    <div className={`col ${rest.containerClass || ''}`} style={{ position: "relative" }}>
      <div className={`col input-group ${className || ''}`} ref={props.InputPropsRef}>
        <label htmlFor={`${id || ''}_input`} className={`${rest.labelClassName || ''} ${required ? 'req' : ''}`}>{label}</label>
        <div className={`row input-container ${Boolean(error) && 'error'} ${disabled && 'disabled'}`} >
          {props.children}
          <i className={`${search ? 'icon-search' : 'icon-chavron-down'} caret`} />
        </div>
      </div>
    </div>
  )
}
const CustomOption = (p) => {
  const { props, option, value } = p;
  const isSelected = () => {
    return (
      (typeof option === "string" && typeof value === "string" && option === value) ||
      (typeof option === "object" && typeof value === "object" && option && value && option.id === value.id)
    )
  }
  return (
    <div className='col arc-option'>
      <FormControlLabel
        {...props}
        control={<Radio checked={isSelected()} sx={{ color: '#9F9F9F' }} />}
        classes={{ root: 'option', label: 'h5 label' }}
        value={option.id}
        label={option.label} />
    </div>
  )
}
export const AutoCompleteSelect = (props) => {
  const { disablePortal, label, id, editable, containerClassName, className, placeholder, group, error, search, disabled, radioOption, allowTypedAsOption, ...rest } = props;
  const [typedVal, setTypedValue] = React.useState('')
  const other = {};
  let _editable = editable === undefined ? true : editable;
  const _ref = useRef(null)
  if (group) {
    other.groupBy = (option) => option.group;
  }
  if (radioOption) {
    other.renderOption = (props, option) => {
      return (
        <CustomOption props={props} option={option} value={rest.value} />
      )
    }
  }
  const removeFocus = () => {
    if (_ref && _ref.current) {
      const input = _ref.current.querySelector('input')
      if (input) input.blur();
    }
  }
  const getOptions = () => {
    let options = [...(props.options || [])];
    if (allowTypedAsOption && typedVal.length > 0) {
      let found = options.find(({ label }) => {
        return String(label).toLowerCase().includes(typedVal.toLowerCase())
      });
      if (!found) {
        options = [{ id: typedVal, label: typedVal }]
      }
    }
    return options;
  };
  return (
    <Autocomplete
      disablePortal={typeof disablePortal !== undefined ? disablePortal : true}
      id={id}
      ref={_ref}
      openOnFocus
      value={rest.value}
      className={containerClassName || ''}
      options={getOptions()}
      onChange={(e, value) => {
        e.preventDefault();
        rest.onChange && rest.onChange({ target: { name: rest.name, value: value } })
        removeFocus()
      }}
      onInputChange={(e, newTypedVal) => {
        if (allowTypedAsOption) {
          setTypedValue(newTypedVal);
        }
      }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
      classes={{ option: "h6 capital", groupLabel: 'h6', noOptions: 'h6' }}
      disabled={disabled}
      noOptionsText='No Matching Result'
      renderOption={(props, option) => {
        return (
          radioOption ?
            <CustomOption props={props} option={option} value={rest.value} />
            :
            <li {...props} key={option.id}>{option.label}</li>
        )
      }}
      renderInput={(params) => {
        const { className, ..._rest } = params.inputProps
        return (
          <DropdownContainer
            {...props}
            containerClass={`${!_editable ? 'input-noedit select' : ''}`}
            InputPropsRef={params.InputProps.ref}>
            {
              _editable ?
                <input id={`${id || ''}_input`} type='text' placeholder={placeholder || label} className={`capital ${className}`} {..._rest} name={rest.name} />
                :
                <div className={`${params.inputProps.className} c254343`}>{rest.value ? rest.value.label : ''}</div>
            }
          </DropdownContainer>
        )
      }}
      {...other}
    />
  )
}
export const TwoLevelDropDown = (props) => {
  const { id, label, placeholder, value, ...rest } = props;
  const _ref = useRef(null);
  const _wrapperRef = useRef(null);
  const _inputRef = useRef(null);
  const [filterText, setFilterText] = useState(value ? value.label : '');
  const [menuProps, toggleMenu] = useMenuState({ transition: true });
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !_wrapperRef?.current?.contains(event.target) &&
        !_inputRef?.current?.contains(event.target)
      ) {
        toggleMenu(false)
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [_wrapperRef]);
  useEffect(() => {
    if (menuProps.state === 'open') {
      if (_inputRef.current) {
        _inputRef.current.focus();
      }
    }
  }, [menuProps]);
  useEffect(() => {
    if (value && value.label) {
      setFilterText(value.label)
    }
  }, [value]);

  const getFilterMenus = () => {
    if (filterText === '') {
      return props.options;
    }
    let filteredOptions = [], filteredSubMenus;
    for (let i = 0; i < props.options.length; i++) {
      const menuItem = props.options[i];
      filteredSubMenus = [];
      for (let j = 0; j < menuItem.items.length; j++) {
        const submenu = menuItem.items[j];
        if (String(submenu.label).toLowerCase().includes(String(filterText).toLowerCase())) {
          filteredSubMenus.push({ ...submenu })
        }
      }
      if (filteredSubMenus.length > 0) {
        filteredOptions.push({ ...menuItem, items: filteredSubMenus })
      }
    }
    if (filteredOptions.length === 0) {
      filteredOptions.push({
        custom: true, label: filterText, id: filterText
      })
    }
    return filteredOptions;
  }
  const onAction = (e) => {
    if (menuProps.state !== 'open') {
      toggleMenu(true)
    }
  }
  const handleInput = (e) => {
    if (e.target.value === '') {
      props.onChange && props.onChange({ target: { name: props.name, value: null } })
    }
  }
  const onSubMenuClick = (subItem) => {
    toggleMenu(false)
    props.onChange && props.onChange({ target: { name: props.name, value: subItem } })
  }
  return (
    <>
      <DropdownContainer InputPropsRef={_ref} {...props} containerClass={rest.containerClass}>
        <input
          id={`${id || ''}_input`}
          type='text'
          className='dropdown-input'
          ref={_inputRef}
          onFocus={onAction}
          value={filterText}
          placeholder={label || placeholder}
          onChange={(e) => {
            setFilterText(e.target.value);
            (typeof props.onTextChange === "function") && props.onTextChange(e.target.value);
          }}
          onInput={handleInput}
          name={rest.name} />
        {/* <button className={'two-level-btn-action'} onClick={onAction}>
          <span className={`${rest.labelClassName || ''}`}>{value ? value.label : (label || placeholder)}</span>
        </button> */}
      </DropdownContainer>
      <ControlledMenu
        anchorRef={_ref}
        {...menuProps}
        ref={_wrapperRef}>
        {
          getFilterMenus().map((_, index) => {
            return (
              _.custom ?
                <MenuItem
                  className='h5'
                  key={_.id}
                  onClick={() => onSubMenuClick(_)}>
                  {_.label}
                </MenuItem>
                :
                <SubMenu key={index} label={_.label} className='h5 dropdown-menu'>
                  {
                    _.items.map((_item) => {
                      return (
                        <MenuItem
                          key={_item.id}
                          onClick={() => onSubMenuClick(_item)}>
                          {_item.label}
                        </MenuItem>
                      )
                    })
                  }
                </SubMenu>
            )
          })
        }

      </ControlledMenu>
    </>
  )
}
