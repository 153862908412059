const Roles = {
  "Auditee": "Auditee",
  "Assessor": "Assessor",
  "Architect": "Architect",
  "Organiser": "Organiser",
  "Administrator": "Administrator"
}
export const getRoles = () => Roles;

export const isAdmin = (user) => {
  return user.role.name === Roles.Administrator
}
export const isOrganizer = (user) => {
  return user.role.name === Roles.Organiser
}
export const isAccessor = (user) => {
  return user.role.name === Roles.Assessor
}
export const isArchitect = (user) => {
  return user.role.name === Roles.Architect
}
export const isAuditee = (user) => {
  return user.role.name === Roles.Auditee
}
export const getCurrentUser = (state) => {
  return state.user.currentUser;
}